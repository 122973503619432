import { Button, Card, DatePicker, message } from "antd";
import moment, { Moment } from "moment";
import React, { useEffect, useState } from "react";
import { http } from "../../helpers/http";
import { AxiosError, AxiosResponse } from "axios";

type CSNTestSettingsProps = {
    student: number;
    category: number;
}

export const CSNTestSettings = ({ ...props }: CSNTestSettingsProps) => {
    const [endDate, setEndDate] = useState(
        moment().add(1, "months") as Moment | null
    );
    const [subscribtion, setSubscribtion] = useState({
        subscribed: 0,
        endDate: null as string | null,
        fromAdmin: 0,
    });
    const [freeSub, setFreeDub] = useState(undefined as undefined | {
        category_id: number;
        free_from: string;
        free_to: string;
        active: number;
    });
    const [loading, setLoading] = useState(false);
    const [dontHaveTests, setDontHaveTests] = useState(false);

    const giveSub = () => {
        http.post(`rest/give-free-test-sub/${props.student}`, {
            category: props.category,
            from: moment().format("YYYY-MM-DD"),
            to: endDate?.format("YYYY-MM-DD"),
            active: 1
        }).then((res: AxiosResponse) => {
            getPaidSubStatus();
            getFreeSubStatus();
        })
    }

    const removeFreeSub = () => {
        setLoading(true);
        http.post(`rest/give-free-test-sub/${props.student}`, {
            category: freeSub?.category_id,
            from: freeSub?.free_from,
            to: freeSub?.free_to,
            active: 0
        }).then((res: AxiosResponse) => {
            setLoading(false);
            getPaidSubStatus();
            getFreeSubStatus();
        }).catch((error: AxiosResponse) => {
            setLoading(false);
            message.error("Kļūda atceļot abonementu");
        })
    }

    const getPaidSubStatus = () => {
        setLoading(true);
        http.get(`rest/csdd-test-subscription/${props.student}/${props.category}`).then((res: AxiosResponse) => {
            setDontHaveTests(false);
            setLoading(false);
            const sub = res.data.data;
            if (sub === null) {
                setSubscribtion({
                    subscribed: 0,
                    endDate: null,
                    fromAdmin: 0,
                })
                return;
            }
            setSubscribtion({
                subscribed: 1,
                endDate: sub.expire_day,
                fromAdmin: sub.free,
            })
        }).catch((error: AxiosError) => {
            setLoading(false);
            if (error.response?.data?.message ? error.response?.data?.message.includes("has_test_subscription") : false) {
                setDontHaveTests(true);
                message.error("Šai kategorijai nav pieejami CSN testi!")
                return
            }
            message.error(error.response?.data?.message || "Kļūda iegūstot abonementa datus");
        })
    }

    const getFreeSubStatus = () => {
        http.get(`rest/get-free-test-sub/${props.student}/${props.category}`).then((res: AxiosResponse) => {
            console.log(res.data);
            setFreeDub(res?.data);
        })
    }

    useEffect(() => {
        getPaidSubStatus();
        getFreeSubStatus();
    }, [props.category])

    return (
        <Card title="CSN Testi" loading={loading}>
            {
                dontHaveTests
                    ? <b style={{color: "red"}}>Šai kategorijai nav pieejami CSN testi!</b>
                    : <>
                        <p>
                            <b>
                                Studentam{" "}
                                <span
                                    style={{
                                        color: subscribtion.subscribed ? "green" : "red",
                                    }}
                                >
                                    {subscribtion.subscribed ? "IR" : "NAV"}
                                </span>{" "}
                                {subscribtion.fromAdmin ? "iedots" : "nopirkts"} abonements
                                {!!subscribtion.endDate && ` līdz ${subscribtion.endDate}`}
                            </b>
                        </p>
                        <b>Abonementa termiņš: </b>
                        <DatePicker
                            value={endDate}
                            format={"DD.MM.YYYY"}
                            onChange={(date) => setEndDate(date)}
                            disabledDate={(current) => {
                                return current < moment().endOf("day");
                            }}
                            disabled={!!subscribtion.subscribed}
                        />
                        <Button
                            disabled={!!subscribtion.subscribed}
                            type="primary"
                            style={{ marginLeft: 10 }}
                            onClick={giveSub}
                        >
                            Piešķirt
                        </Button>
                        {
                            subscribtion.fromAdmin && subscribtion.subscribed
                                ? <Button
                                    disabled={!!!subscribtion.fromAdmin && !!subscribtion.subscribed}
                                    type="primary"
                                    danger
                                    style={{ marginLeft: 10 }}
                                    onClick={freeSub ? () => removeFreeSub() : () => { }}
                                >
                                    Atcelt
                                </Button>
                                : <></>
                        }
                    </>
            }
        </Card>
    );
};
