import React, { useEffect, useState } from "react";
import { Card, Layout, message, PageHeader, Table } from "antd";
import { StarFilled } from "@ant-design/icons";
import { useHistory } from "react-router";
import { http } from "../../helpers/http";
import { useTranslation } from "react-i18next";
import { getColumnSearchProps } from "../../helpers/getColumnSearchProps";

export const LessonRatingPage = (props: any) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [feedbacks, setFeedbacks] = useState([] as any);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 15,
    total: 0,
  } as any);

  const getParams = (params: any) => {
    return {
      page: params.pagination.current,
      sortField: params.sortField,
      sortOrder: params.sortOrder,
      filters: params.filters 
    };
  };

  const fetchAllFeedbacks = async (params: any = { pagination: { current: 1 } }) => {
    setLoading(true);
    try {
      const response = await http.get("/rest/instructor-service-feed", {
        params: getParams(params),
      });
      //console.log("API Response:", response);
      const { data, page, total } = response.data;

      setFeedbacks(data);
      setPagination({
        current: Number(page),
        pageSize: 15,
        total: total,
      });
      //message.success("Atsauksmes ielādētas!");
    } catch (error) {
      console.error("Error:", error);
      message.error("Kļūda ielādējot atsauksmes!");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    document.getElementById("feedback-card")?.scrollIntoView({ behavior: "smooth" });
  }, []);

  useEffect(() => {
    fetchAllFeedbacks();
  }, []);

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    console.log(filters, sorter, pagination);
    const filtersCopy = { ...filters };
    fetchAllFeedbacks({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      filters: filtersCopy,
    });
  };

  const columns = [
    {
      title: "Instruktora vērtējums",
      dataIndex: "instructor_rating",
      key: "instructor_rating",
      width: 120,
      align: "center",
      sorter: true,
      fixed: "left",
      render: (rating: number | null) => (
        <>
          {rating ? <StarFilled style={{ color: "gold" }} /> : "N/A"} {rating || ""}
        </>
      ),
      ...getColumnSearchProps("instructor_rating"),
    },
    {
      title: "Auto vērtējums",
      dataIndex: "car_cleanliness_rating",
      key: "car_cleanliness_rating",
      width: 120,
      align: "center",
      sorter: true,
      fixed: "left",
      render: (rating: number | null) => (
        <>
          {rating ? <StarFilled style={{ color: "gold" }} /> : "N/A"} {rating || ""}
        </>
      ),
      ...getColumnSearchProps("car_cleanliness_rating"),
    },
    {
      title: "Instruktora vārds, uzvārds",
      dataIndex: "instructor",
      key: "instructor",
      width: 180,
      sorter: true,
      ...getColumnSearchProps("instructor"),
    },
    {
      title: "Kategorija, auto, serviss",
      key: "service_details",
      width: 220,
      sorter: true,
      render: (record: any) => (
        <span>{`${record.category}, ${record.licence_plate}, ${record.vehicle}`}</span>
      ),
    },
    {
      title: "Studenta vārds, uzvārds",
      dataIndex: "student",
      key: "student",
      width: 180,
      sorter: true,
      ...getColumnSearchProps("student"),
    },
    {
      title: "Datums",
      dataIndex: "created_at",
      key: "created_at",
      width: 120,
      sorter: true,
      render: (date: string) => (date ? date.split("T")[0] : ""),
      ...getColumnSearchProps("created_at"),
    },
    {
      title: "Komentārs",
      dataIndex: "comment",
      key: "comment",
      render: (text: string) => text,
      ...getColumnSearchProps("comment"),
    },
  ] as any;

  return (
    <Layout>
      <PageHeader
        title="Nodarbību vērtējums"
        onBack={history.goBack}
        style={{ backgroundColor: "#FFFFFF" }}
      />
      <Card id="feedback-card">
        <Table
          style={{ marginTop: 20 }}
          rowKey={(record) => record.id} 
          onChange={handleTableChange}
          columns={loading ? [] : columns}
          dataSource={feedbacks}
          pagination={{
            showSizeChanger: false,
            size: "small",
            showTotal: (total) => <div>Kopā {total} Atsauksmes</div>,
            position: ["bottomCenter"],
            pageSizeOptions: ["15"],
            defaultPageSize: 15,
            ...pagination,
          }}
          loading={loading}
          scroll={{ x: 1200 }}
          size="middle"
        />
      </Card>
    </Layout>
  );
};
