import { Layout, Button, Table, Card, Row, Col, Form, Input, Skeleton, Checkbox, Tag, Select, Tabs, message, Collapse, PageHeader } from "antd";
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from "react";
import { Link, Route, RouteComponentProps, Switch, useHistory, useParams, withRouter } from "react-router-dom";
import { http } from "../helpers/http";
import { getEnabledCategories } from "trace_events";
import isoArr from "../locale/ISO-639-1-language.json";
import { TextEditor } from "./TextEdtior";
import { ExamPrices } from "./ExamPrices";
import { StudyTypes } from "./StudyTypes";
import { DrivingGlobalSettings } from "./DrivingGlobalSettings";
import { SchoolGlobalSettings } from "./SchoolGlobalSettings";
import { ImgUploader } from "./Elearning/components/ImgUploader";
import { GlobalDiscountsSettings } from "./GlobalDiscountsSettings";

const { Header, Content } = Layout;
const { TabPane } = Tabs;

export type ClassifierOptionTableProps = {
    title: string,
    url: string,
    addNew: string,
    editBase: string
}

export type ClassifierOptionTableState = {
    loading: boolean,
    data: any
};

const StudyServiceTypes = (props: any) => {
    const [types, setTypes] = useState([] as any);
    const [loading, setLoading] = useState(false);

    const fetchTypes = async () => {
        setLoading(true);
        await http.get('rest/study-groups-service-types').then((response: any) => {
            console.log(response)
            setTypes(response.data.data);
            setLoading(false);
        })
    };

    const typesColumns =
        [
            {
                title: "Mācību grupu pakalpojumu veidi",
                dataIndex: 'value_lv',
                key: 'value_lv',
            },
            {
                width: 1,
                title: <Link to="/options/settings/service-types/new"> <Button type="primary" icon={<PlusOutlined />}></Button></Link >,
                render: (record: any) => {
                    return <Link to={`/options/settings/service-types/edit/${record.id}`}><Button type="link" icon={<EditOutlined />}></Button></Link>
                }
            }
        ]

    useEffect(() => {
        fetchTypes();
    }, []);

    return <Table
        rowKey={record => record.id}
        dataSource={types}
        columns={typesColumns}
        pagination={false}
        loading={loading}
    />
}

const StudyServiceTypesForm = (props: any) => {
    const history = useHistory();
    const { id } = useParams() as any;
    const [type, setType] = useState({} as any);
    const [loading, setLoading] = useState(false);
    const [cities, setCities] = useState([] as any);

    const fetchCities = () => {
        http.get('rest/cities').then((response: any) => {
            setCities(response.data.data);
        })
    }

    const fetchType = async () => {
        setLoading(true);
        await http.get(`rest/study-groups-service-types/${id}`).then((response: any) => {
            setType(response.data.data);
            setLoading(false);
        })
    };

    const saveType = async () => {
        setLoading(true);
        if (id) {
            const newType = JSON.parse(JSON.stringify(type));
            if (newType.hasOwnProperty("img")) {
                if (newType.img === null) delete newType.img
                else if (!newType.img.includes("base64")) delete newType.img
            } else {
                await http.post(`/rest/delete-img-group-type/${id}`)
            }
            await http.patch(`rest/study-groups-service-types/${id}`, newType).then((response: any) => {
                history.goBack();
            })
        } else {
            await http.post(`rest/study-groups-service-types`, type).then((response: any) => {
                history.goBack();
            })
        }
    };

    useEffect(() => {
        fetchCities();
        if (id) {
            fetchType();
        }
    }, []);

    useEffect(() => {
        console.log(type)
    }, [type]);

    return <>
        <PageHeader
            onBack={history.goBack}
            title={"Mācību grupas pakalpojumu uzstādījumi"}
        />
        <Card
            title="Mācību grupas pakalpojums"
        >
            <Row gutter={16}>
                <Col>
                    <p>Latviešu val</p>
                    <Input
                        value={type?.value_lv}
                        onChange={(e: any) => {
                            e.persist();
                            setType((prev: any) => {
                                return {
                                    ...prev,
                                    value_lv: e.target.value
                                }
                            })
                        }}
                    />
                </Col>
                <Col>
                    <p>Angļu val</p>
                    <Input
                        value={type?.value_en}
                        onChange={(e: any) => {
                            e.persist();
                            setType((prev: any) => {
                                return {
                                    ...prev,
                                    value_en: e.target.value
                                }
                            })
                        }}
                    />
                </Col>
                <Col>
                    <p>Krievu val</p>
                    <Input
                        value={type?.value_ru}
                        onChange={(e: any) => {
                            e.persist();
                            setType((prev: any) => {
                                return {
                                    ...prev,
                                    value_ru: e.target.value
                                }
                            })
                        }}
                    />
                </Col>
                <Col>
                    <p>Kopīgs visām grupām</p>
                    <Checkbox
                        checked={!!type?.common}
                        onChange={(e: any) => {
                            setType((prev: any) => {
                                return {
                                    ...prev,
                                    common: e.target.checked ? 1 : 0
                                }
                            })
                        }}
                    />
                </Col>
                <Col>
                    <p>Atļautās pilsētas (atstāt tukšu, ja visās)</p>
                    <Select
                        style={{ width: 200 }}
                        value={type?.type_cities}
                        onChange={(value: any) => {
                            setType((prev: any) => {
                                return {
                                    ...prev,
                                    type_cities: value
                                }
                            })
                        }}
                        mode="multiple"
                    >
                        {
                            cities.map((city: any) => {
                                return <Select.Option key={city.id} value={city.id}>{city.value}</Select.Option>
                            })
                        }
                    </Select>
                </Col>
                {
                    (Object.keys(type).length > 0 || !id)
                    && <Col span={24} style={{ paddingTop: 20 }}>
                        <ImgUploader
                            preUrl={"study_group_types/"}
                            img={type.img}
                            setImg={(img: any) => {
                                setType((prev: any) => {
                                    return {
                                        ...prev,
                                        img
                                    }
                                })
                            }}
                        />
                    </Col>
                }
                <Col span={24} style={{ paddingTop: 20 }}>
                    <Button type="primary" onClick={saveType}>Saglabāt</Button>
                </Col>
            </Row>
        </Card>
    </>
}

const CSDDCredentials = (props: any) => {
    const [status, setStatus] = useState("" as "new" | "edit" | "loged");
    const [credentials, setCredentials] = useState({} as any);
    const [loading, setLoading] = useState(true);

    const fetchData = async () => {
        setLoading(true);
        await http.get(`rest/csdd-credentials`).then((response: any) => {
            setLoading(false);
            if (typeof (response.data) !== "string") {
                setCredentials(response.data);
                setStatus("loged");
            } else {
                setStatus("new");
            }
        }).catch((err: any) => {
            console.log(err)
        })
    }

    const handleInputs = (e: any) => {
        e.persist()
        setCredentials((data: any) => {
            return {
                ...data,
                [e.target.name]: e.target.value
            }
        })
    }

    const editCreds = () => {
        setStatus("edit");
    }

    const saveData = () => {
        if (status === "edit") {
            http.patch(`rest/csdd-credentials/${credentials.id}`, credentials).then((reponse: any) => {
                message.success("Dati ir saglabāti!")
                fetchData();
            });
        } else {
            http.post('rest/csdd-credentials', credentials).then((reponse: any) => {
                message.success("Dati ir saglabāti!")
                fetchData();
            });
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        console.log(credentials)
    }, [credentials])

    return (
        loading
            ? <Skeleton />
            : <div>
                {
                    status === "loged"
                        ? <>
                            <b>Lietotājs: {credentials.username}</b><br />
                            <b>Iestāde: {credentials.institution}</b><br />
                            <b>API lietotājs: {credentials.api_username}</b><br />
                            <Button
                                style={{ marginLeft: 15 }}
                                type="text"
                                onClick={editCreds}
                                icon={<EditOutlined style={{ color: "#1890FF" }}
                                />}>
                            </Button>
                        </>
                        : <div>
                            <b>Lietotājs:</b><br />
                            <Input
                                value={credentials.username}
                                name="username"
                                style={{
                                    width: 200,
                                    display: "block",
                                    marginBottom: 8
                                }}
                                type="text"
                                placeholder="Lietotāja vārds"
                                onChange={handleInputs}
                            />
                            <b>Parole:</b><br />
                            <Input
                                value={credentials.password}
                                name="password"
                                style={{
                                    width: 200,
                                    display: "block",
                                    marginBottom: 10
                                }}
                                type="password"
                                placeholder="Parole"
                                onChange={handleInputs}
                            />
                            <b>Iestāde:</b><br />
                            <Input
                                value={credentials.institution}
                                name="institution"
                                style={{
                                    width: 200,
                                    display: "block",
                                    marginBottom: 8
                                }}
                                type="text"
                                placeholder="Iestāde"
                                onChange={handleInputs}
                            />
                            <b>API lietotājs:</b><br />
                            <Input
                                value={credentials.api_username}
                                name="api_username"
                                style={{
                                    width: 200,
                                    display: "block",
                                    marginBottom: 8
                                }}
                                type="text"
                                placeholder="Lietotāja vārds"
                                onChange={handleInputs}
                            />
                            <b>API parole:</b><br />
                            <Input
                                value={credentials.api_password}
                                name="api_password"
                                style={{
                                    width: 200,
                                    display: "block",
                                    marginBottom: 10
                                }}
                                type="password"
                                placeholder="Parole"
                                onChange={handleInputs}
                            />
                            <b>API sysGUID:</b><br />
                            <Input.TextArea
                                value={credentials.api_sysguid}
                                name="api_sysguid"
                                autoSize={{ minRows: 3, maxRows: 3 }}
                                style={{
                                    width: 200,
                                    display: "block",
                                    marginBottom: 10
                                }}
                                onChange={handleInputs}
                            />
                            <Button onClick={saveData}>Saglabāt</Button>
                        </div>
                }

            </div>
    )
}

export class ClassifierOptionTable extends React.Component<ClassifierOptionTableProps, ClassifierOptionTableState> {

    constructor(props: ClassifierOptionTableProps) {
        super(props);

        this.state = {
            loading: true,
            data: []
        }
    }

    componentDidMount() {
        console.log(this.props)
        http.get(this.props.url).then((response: any) => {
            const data = response.data.data
            this.setState({
                data,
                loading: false
            });
        })
    }

    statusColumns =
        [
            {
                title: this.props.title,
                dataIndex: 'value',
                key: 'value',
            },
            {
                title: "Krāsa",
                dataIndex: "icon_color",
                key: 'color',
                render: (color: any) => <Tag style={{ height: 20, width: 20 }} color={"#" + color}></Tag>
            },
            {
                width: 1,
                title: <Link to={this.props.addNew}><Button type="primary" icon={<PlusOutlined />}></Button></Link>,
                render: (record: any) => {
                    return <Link to={`${this.props.editBase}/${record.id}`}><Button type="link" icon={<EditOutlined />}></Button></Link>
                }
            }
        ]

    categoryColumns =
        [
            {
                title: this.props.title,
                dataIndex: 'value',
                key: 'value',
            },
            {
                title: "Web prioritāte",
                dataIndex: "priority",
                key: 'priority',
            },
            {
                width: 1,
                title: <Link to={this.props.addNew}><Button type="primary" icon={<PlusOutlined />}></Button></Link>,
                render: (record: any) => {
                    return <Link to={`${this.props.editBase}/${record.id}`}><Button type="link" icon={<EditOutlined />}></Button></Link>
                }
            }
        ]

    columns = [
        {
            title: this.props.title,
            dataIndex: 'value',
            key: 'value',
        },
        {
            width: 1,
            title: <Link to={this.props.addNew}><Button type="primary" icon={<PlusOutlined />}></Button></Link>,
            render: (record: any) => {
                return <Link to={`${this.props.editBase}/${record.id}`}><Button type="link" icon={<EditOutlined />}></Button></Link>
            }
        }
    ]

    render() {
        return (
            <Table
                loading={this.state.loading}
                rowKey={record => record.id}
                columns={
                    this.props.url === "/rest/study-groups-status"
                        ? this.statusColumns
                        : this.props.url === "/rest/categories"
                            ? this.categoryColumns
                            : this.columns
                }
                pagination={false}
                dataSource={this.state.data}
            />
        );
    }

}

export type EditOptionPageProps = RouteComponentProps<{ id: string }> & {
    title: string,
    url: string
}

export type EditOptionPageState = {
    initialData: any,
    checkedExam: boolean,
    checkedTestDrive: boolean,
    hasCsddExam: boolean,
    haveOrphan: boolean,
    haveStudyFrequency: boolean,
    pmp: boolean,
    examCities: [],
    categories: [],
    agreements: {},
    csddCategoryCodes: [],
    use_autogroups: boolean
    elearning_edrive_available: boolean,
    has_test_subscription: boolean;
    allCategories: any[];
};

export class BaseEditOptionPage extends React.Component<EditOptionPageProps, EditOptionPageState> {
    constructor(props: EditOptionPageProps) {
        super(props);

        this.state = {
            initialData: null,
            checkedExam: false,
            checkedTestDrive: false,
            hasCsddExam: false,
            haveOrphan: false,
            haveStudyFrequency: false,
            pmp: false,
            examCities: [],
            categories: [],
            agreements: {},
            csddCategoryCodes: [],
            use_autogroups: false,
            elearning_edrive_available: false,
            has_test_subscription: false,
            allCategories: []
        };
    }



    onFinish = (values: any) => {
        //console.log("Masīvais payload ar saglabatiem datiem:", values);

        if (this.props.url === "/rest/languages") {
            const isoEntry = isoArr.find((el: any) => el.name === values.value) as any;
            values.iso = isoEntry?.code;
            values.value_lv = isoEntry?.value_lv;
            values.value_en = isoEntry?.value_en;
            values.value_ru = isoEntry?.value_ru;
        }
        if (this.props.url === "/rest/cities") {
            values.near_exam_cities = [values.near_exam_cities]
            values.same_instructor_exam = values.same_instructor_exam ? 1 : 0;
        }
        if (this.props.url === "/rest/categories") {
            values = {
                ...values,
                ...this.state.agreements,
                have_orphan: values.have_orphan ? 1 : 0,
                have_study_frequency: values.have_study_frequency ? 1 : 0,
                pmp: values.pmp ? 1 : 0,
                has_theory_exam: values.has_theory_exam ? 1 : 0,
                use_autogroups: this.state.use_autogroups ? 1 : 0,
                use_affiliate: values.use_affiliate ? 1 : 0,
                elearning_edrive_available: values.elearning_edrive_available ? 1 : 0,
                has_test_subscription: values.has_test_subscription ? 1 : 0,
                driving_options: values.driving_options ? 1 : 0,
                can_only_drive: values.can_only_drive ? 1 : 0,
                full_eval: values.full_eval ? 1 : 0,
                agreement_based_on_driven_lessons: values.agreement_based_on_driven_lessons ? 1 : 0,
            }
        }
        // values.web = values.web ? 1 : 0;
        values.new_group = values.new_group ? 1 : 0;
        values.has_exam = values.has_exam ? 1 : 0;
        values.icon_color = values.icon_color !== undefined ? values.icon_color.substring(1) : undefined;
        http.patch(`${this.props.url}/${this.props.match.params.id}`, values).then((response: any) => {
            this.props.history.replace("/options/settings");
            console.log(response)
        })
    };

    onFinishFailed = () => { };

    componentDidMount() {
        http.get(`${this.props.url}/${this.props.match.params.id}`).then((response: any) => {
            console.log(response.data.data, this.props.url)
            this.setState({
                initialData: this.props.url === '/rest/cities' ? response.data.data.city : response.data.data,
                hasCsddExam: response.data.data?.city?.has_exam || false,
                checkedExam: response.data.data.exam || false,
                checkedTestDrive: response.data.data.test_drive || false,
                haveOrphan: response.data.data.have_orphan || false,
                haveStudyFrequency: response.data.data.have_study_frequency || false,
                pmp: response.data.data.pmp || false
            });
        })
        http.get(`${this.props.url}`).then((response: any) => {
            console.log(response.data.data)
            this.setState({
                categories: response.data.data.filter((item: any) => !item.exam),
                allCategories: response.data.data,
                examCities: this.props.url === '/rest/cities'
                    ? response.data.data.filter((city: any) => city.has_exam)
                    : response.data.data,
            })
        })
        if (this.props.url === "/rest/categories") {
            http.get(`rest/get-csdd-codes`).then((response: any) => {
                this.setState({ csddCategoryCodes: response.data.data })
            })
        }
    }

    agreementsHandler = (key: any, data: any) => {
        this.setState({
            agreements: {
                ...this.state.agreements,
                [key]: data
            }
        })
    }

    render() {
        return this.state.initialData != null ? <Card title={<strong>{this.props.title}</strong>}>
            <Form
                name="basic"
                onFinish={this.onFinish}
                onFinishFailed={this.onFinishFailed}
                initialValues={
                    {
                        ...this.state.initialData,
                        icon_color: "#" + this.state.initialData.icon_color,
                        test_for_category: this.state.initialData?.test_for_category?.[0]?.category ?? undefined
                    }
                }
            >
                <Row gutter={20}>
                    <Col span={4}>
                        {/* <Button onClick={() => console.log(isoArr)}>ISO</Button> */}
                        <Form.Item name="value" required rules={[{ required: true, whitespace: true, message: "Obligāts lauks" }]}>
                            {this.props.url === "/rest/languages"
                                ? <Select showSearch style={{ minWidth: 200 }}>
                                    {isoArr.map((lang: any) => {
                                        return <Select.Option key={lang.code} value={lang.name}>{lang.name}</Select.Option>
                                    })}
                                </Select>
                                : <Input />}
                        </Form.Item>
                        {
                            this.props.url === "/rest/categories"
                            && <div>
                                <p>CSDD kategorija</p>
                                <Form.Item style={{ width: 200 }} name="csdd_code">
                                    <Select>
                                        {
                                            this.state.csddCategoryCodes.map((cat: any) => {
                                                return <Select.Option value={cat.code}>{cat.code}</Select.Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                                <Form.Item valuePropName="checked" name="ask_transmission" label="Jautāt pārnesumkārbu">
                                    <Checkbox />
                                </Form.Item>
                                <Form.Item valuePropName="checked" name="use_affiliate" label="Bonusu sistēma">
                                    <Checkbox />
                                </Form.Item>
                            </div>
                        }
                    </Col>
                    {this.props.url === "/rest/categories"
                        ? <>
                            <Col span={12}>
                                <h3>Braukšanas nodarbības</h3>
                                <Col>
                                    <Form.Item valuePropName="checked" name="exam" label="Eksāmens">
                                        <Checkbox value={this.state.checkedExam} onChange={(e: any) => this.setState({ checkedExam: e.target.checked })} />
                                    </Form.Item>
                                    <Form.Item valuePropName="checked" name="allow_send_to_csdd_without_school_exam" label="Nosūtīt uz CSDD bez skolas braukšanas eksāmena">
                                        <Checkbox />
                                    </Form.Item>
                                    <Form.Item valuePropName="checked" name="test_drive" label="Testa brauciena kategorija">
                                        <Checkbox value={this.state.checkedTestDrive} onChange={(e: any) => this.setState({ checkedTestDrive: e.target.checked })} />
                                    </Form.Item>
                                </Col>
                                {
                                    this.state.checkedExam
                                        ? <Col span={6}>
                                            <Form.Item initialValue={this.state.initialData.exams?.exam_category_id} name="exam_category" label="Eksāmena kategorija">
                                                <Select>
                                                    {this.state.categories.map((cat: any) => {
                                                        return <Select.Option key={cat.id} value={cat.id}>{cat.value}</Select.Option>
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        : <>
                                            <Col>
                                                <Form.Item name="min_driving_lessons" label="Minimālais braukšanas nodarbību daudzums">
                                                    <Input style={{ width: 50 }} />
                                                </Form.Item>
                                            </Col>
                                            <Col>
                                                <Form.Item valuePropName="checked" name="web" label="Rādīt WEB">
                                                    <Checkbox />
                                                </Form.Item>
                                            </Col>
                                        </>
                                }
                                {
                                    this.state.checkedTestDrive
                                    && <Col span={6}>
                                        <Form.Item name="test_for_category" label="Testa brauciens kategorijai">
                                            <Select>
                                                {this.state?.categories?.map((cat: any) => {
                                                    return <Select.Option key={cat.id} value={cat.id}>{cat.value}</Select.Option>
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                }
                                <Col>
                                    <Form.Item name="daily_driving_limit" label="Dienas braukšanu limits">
                                        <Input style={{ width: 50 }} />
                                    </Form.Item>
                                    <Form.Item valuePropName="checked" name="full_eval" label="Padziļinātā vērtēšana">
                                        <Checkbox style={{ width: 50 }} />
                                    </Form.Item>
                                    <Form.Item valuePropName="checked" name="driving_options" label="Braukšanu opcijas pie slota">
                                        <Checkbox style={{ width: 50 }} />
                                    </Form.Item>
                                    <Form.Item valuePropName="checked" name="can_only_drive" label="Ir iespēja tikai braukt">
                                        <Checkbox style={{ width: 50 }} />
                                    </Form.Item>
                                    <Form.Item name="equal_driving_cats" label="Kategorijas kuras var aizvietot:">
                                        <Select mode="multiple">
                                            {this.state?.allCategories?.map((cat: any) => {
                                                return <Select.Option key={cat.id} value={Number(cat.id)}>{cat.value}</Select.Option>
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Col>
                            <Col>
                                <Form.Item valuePropName="checked" name="pmp" label="PMP">
                                    <Checkbox checked={this.state.pmp} onChange={(e: any) => this.setState({ pmp: e.target.checked })} />
                                </Form.Item>
                            </Col>
                            <Col>
                                {
                                    this.props.url === "/rest/categories"
                                        ? <Link to={`${this.props.match.params.id}/exam-prices`}>
                                            <Button
                                                style={{ marginBottom: 20 }}
                                                type="primary">
                                                CSDD pakalpojumu cenas
                                            </Button>
                                        </Link>
                                        : <></>
                                }
                            </Col>
                            <Col span={24} style={{ marginBottom: 20 }}>
                                <Card>
                                    <h3>Automātiskā online grupu ģenerēšana</h3>
                                    <Col>
                                        <Form.Item valuePropName="checked" name="use_autogroups" label="Ģenerēt grupas automātiski">
                                            <Checkbox checked={this.state.use_autogroups} onChange={(e: any) => this.setState({ use_autogroups: e.target.checked })} />
                                        </Form.Item>
                                    </Col>
                                    <Col>
                                        <Form.Item name="autogroup_days" label="Cik dienas uz priekšu pārbaudīt">
                                            <Input style={{ width: 80 }} type="number" />
                                        </Form.Item>
                                    </Col>
                                    <Col>
                                        <Form.Item name="autogroup_slot_limit" label="Brīvo vietu(soft, hard) minimums laika posmā">
                                            <Input style={{ width: 80 }} type="number" />
                                        </Form.Item>
                                    </Col>
                                </Card>
                            </Col>
                            <Col span={12} style={{ marginBottom: 8 }}>
                                <Collapse>
                                    <Collapse.Panel header='lv' key='lv' forceRender>
                                        <Form.Item name="info_lv" label="Papildus informācija" >
                                            <Input.TextArea autoSize={{ maxRows: 3, minRows: 3 }} />
                                        </Form.Item>
                                    </Collapse.Panel>
                                    <Collapse.Panel header='en' key='en' forceRender>
                                        <Form.Item name="info_en" label="Papildus informācija" >
                                            <Input.TextArea autoSize={{ maxRows: 3, minRows: 3 }} />
                                        </Form.Item>
                                    </Collapse.Panel>
                                    <Collapse.Panel header='ru' key='ru' forceRender>
                                        <Form.Item name="info_ru" label="Papildus informācija" >
                                            <Input.TextArea autoSize={{ maxRows: 3, minRows: 3 }} />
                                        </Form.Item>
                                    </Collapse.Panel>
                                </Collapse>
                            </Col>
                            <Col>
                                <Form.Item name="priority" label="WEB prioritāte" >
                                    <Input style={{ width: 100 }} type="number" min={0} step={1} />
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item valuePropName="checked" name="have_orphan" label="Var būt nepieciešams Aizgādnis" >
                                    <Checkbox onChange={(e: any) => this.setState({ haveOrphan: e.target.checked })} />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Card>
                                    <Row>
                                        <Form.Item
                                            style={{ marginRight: 30 }}
                                            name="elearning_module_exam_fee"
                                            label="E-apmācības moduļa atkārtošanas cena"
                                        >
                                            <Input style={{ width: 80 }} type="number" min={0.00} step={0.01} />
                                        </Form.Item>
                                        <Form.Item
                                            style={{ marginRight: 30 }}
                                            valuePropName="checked"
                                            name="have_study_frequency"
                                            label="Teorijas nodarbību ierobežojums"
                                        >
                                            <Checkbox onChange={(e: any) => this.setState({ haveStudyFrequency: e.target.checked })} />
                                        </Form.Item>
                                        {
                                            this.state.haveStudyFrequency
                                                ? <Form.Item
                                                    style={{ marginRight: 30 }}
                                                    name="study_frequency"
                                                    label="Daudzums nedēļā"
                                                >
                                                    <Input defaultValue={1} style={{ width: 100 }} type="number" min={1} step={1} />
                                                </Form.Item>
                                                : <></>
                                        }
                                        <Form.Item
                                            style={{ marginRight: 30 }}
                                            valuePropName="checked"
                                            name="has_theory_exam"
                                            label="Teorijas eksāmens"
                                        >
                                            <Checkbox />
                                        </Form.Item>
                                        <Form.Item
                                            style={{ marginRight: 30 }}
                                            name="theory_exam_price"
                                            label="Skolas teorijas eksāmena cena"
                                        >
                                            <Input style={{ width: 80 }} type="number" min={0.00} step={0.01} />
                                        </Form.Item>
                                        <Form.Item
                                            style={{ marginRight: 30 }}
                                            name="theory_exam_balance"
                                            label="Iemaksa teorijas eksāmenam"
                                        >
                                            <Input style={{ width: 80 }} type="number" min={0.00} step={0.01} />
                                        </Form.Item>
                                        <Form.Item
                                            style={{ marginRight: 30 }}
                                            name="theory_exam_age"
                                            label="Skolas teorijas eksāmena minimālais vecums"
                                        >
                                            <Input style={{ width: 80 }} type="number" min={1} step={1} />
                                        </Form.Item>
                                        <Form.Item
                                            style={{ marginRight: 30 }}
                                            name="exam_max_errors"
                                            label="Skolas teorijas eksāmena pieļaujamais kļūdu daudzums"
                                        >
                                            <Input style={{ width: 80 }} type="number" min={1} step={1} />
                                        </Form.Item>
                                        <Form.Item
                                            style={{ marginRight: 30 }}
                                            name="exam_time_limit"
                                            label="Skolas teorijas eksāmena laika ierobežojums"
                                        >
                                            <Input style={{ width: 80 }} type="number" min={1} step={1} />
                                        </Form.Item>
                                        <Form.Item
                                            style={{ marginRight: 30 }}
                                            name="exam_test_time_limit"
                                            label="Skolas teorijas eksāmena testa laika ierobežojums"
                                        >
                                            <Input style={{ width: 80 }} type="number" min={1} step={1} />
                                        </Form.Item>
                                        <Form.Item
                                            style={{ marginRight: 30 }}
                                            name="exam_questions_count"
                                            label="Skolas teorijas eksāmena jautājumu skaits"
                                        >
                                            <Input style={{ width: 80 }} type="number" min={1} step={1} />
                                        </Form.Item>
                                        <Form.Item
                                            style={{ marginRight: 30 }}
                                            valuePropName="checked"
                                            name="elearning_edrive_available"
                                            label="Pieejama eDrive platformā"
                                        >
                                            <Checkbox />
                                        </Form.Item>
                                    </Row>
                                </Card>
                            </Col>
                            <Col span={24}>
                                <Card style={{ marginTop: 20, marginBottom: 20 }}>
                                    <Form.Item
                                        style={{ marginRight: 30 }}
                                        valuePropName="checked"
                                        name="has_test_subscription"
                                        label="Pieejams CSN abonements"
                                    >
                                        <Checkbox />
                                    </Form.Item>
                                    <Form.Item label="Pieejams valodās:" name="test_languages">
                                        <Select style={{ width: 120 }} allowClear mode="multiple">
                                            <Select.Option value={1}>LV</Select.Option>
                                            <Select.Option value={3}>EN</Select.Option>
                                            <Select.Option value={2}>RU</Select.Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        style={{ marginRight: 30 }}
                                        name="demo_test_counter"
                                        label="Demo testu skaits"
                                    >
                                        <Input style={{ width: 80 }} type="number" min={1} step={1} />
                                    </Form.Item>
                                    <Form.Item
                                        style={{ marginRight: 30 }}
                                        name="demo_test_questions"
                                        label="Demo testa jautājumu skaits"
                                    >
                                        <Input style={{ width: 80 }} type="number" min={1} step={1} />
                                    </Form.Item>
                                    <Form.Item
                                        style={{ marginRight: 30 }}
                                        name="demo_test_mistakes"
                                        label="Demo testa kļūdu skaits"
                                    >
                                        <Input style={{ width: 80 }} type="number" min={1} step={1} />
                                    </Form.Item>
                                    <Form.Item
                                        style={{ marginRight: 30 }}
                                        name="demo_test_timer"
                                        label="Demo testa laika ierobežojums (min.)"
                                    >
                                        <Input style={{ width: 80 }} type="number" min={1} step={1} />
                                    </Form.Item>
                                    <Form.Item
                                        style={{ marginRight: 30 }}
                                        name="subscription_price"
                                        label="Abonementa cena (euro)"
                                    >
                                        <Input style={{ width: 80 }} type="number" min={1} step={0.01} />
                                    </Form.Item>
                                    <Form.Item
                                        style={{ marginRight: 30 }}
                                        name="subscription_price_discounted"
                                        label="Abonementa cena ar atlaidi (euro)"
                                    >
                                        <Input style={{ width: 80 }} type="number" min={1} step={0.01} />
                                    </Form.Item>
                                    <Form.Item
                                        style={{ marginRight: 30 }}
                                        name="test_questions"
                                        label="Abonēta testa jautājumu skaits"
                                    >
                                        <Input style={{ width: 80 }} type="number" min={1} step={1} />
                                    </Form.Item>
                                    <Form.Item
                                        style={{ marginRight: 30 }}
                                        name="test_mistakes"
                                        label="Abonēta testa kļūdu skaits"
                                    >
                                        <Input style={{ width: 80 }} type="number" min={1} step={1} />
                                    </Form.Item>
                                    <Form.Item
                                        style={{ marginRight: 30 }}
                                        name="test_timer"
                                        label="Abonēta testa laika ierobežojums (min.)"
                                    >
                                        <Input style={{ width: 80 }} type="number" min={1} step={1} />
                                    </Form.Item>
                                    <Form.Item
                                        style={{ marginRight: 30 }}
                                        name="test_failed_counter"
                                        label="Cik jāsakrāj nenokārtoti jautājumi, lai varētu veikt testu tikai ar tiem"
                                    >
                                        <Input style={{ width: 80 }} type="number" min={1} step={1} />
                                    </Form.Item>
                                    <Form.Item
                                        style={{ marginRight: 30 }}
                                        name="test_failed_period"
                                        label="Periods kurā uzglabāt nenokārtotus jautājumus (dienas)"
                                    >
                                        <Input style={{ width: 80 }} type="number" min={1} step={1} />
                                    </Form.Item>
                                    <Form.Item
                                        style={{ marginRight: 30 }}
                                        name="test_subscription_period"
                                        label="Abonementa periods (dienas)"
                                    >
                                        <Input style={{ width: 80 }} type="number" min={1} step={1} />
                                    </Form.Item>
                                </Card>
                            </Col>
                            <Col span={24}>
                                <Card>
                                    <Form.Item
                                        style={{ marginRight: 30 }}
                                        valuePropName="checked"
                                        name="agreement_based_on_driven_lessons"
                                        label="Līgums balstīts uz braukšanas nodarbībām"
                                    >
                                        <Checkbox />
                                    </Form.Item>
                                    <Form.Item
                                        style={{ marginRight: 30 }}
                                        name="min_driven_lessons"
                                        label="Minimālai braukto nodarbību daudzums"
                                    >
                                        <Input style={{ width: 80 }} type="number" min={1} step={1} />
                                    </Form.Item>
                                </Card>
                                <Tabs defaultActiveKey="1">
                                    <TabPane key="1" tab="LV" forceRender>
                                        <TextEditor lang="agreement_lv" returnData={this.agreementsHandler} data={this.state.initialData.agreement_lv} />
                                    </TabPane>
                                    <TabPane key="2" tab="EN" forceRender>
                                        <TextEditor lang="agreement_en" returnData={this.agreementsHandler} data={this.state.initialData.agreement_en} />
                                    </TabPane>
                                    <TabPane key="3" tab="RU" forceRender>
                                        <TextEditor lang="agreement_ru" returnData={this.agreementsHandler} data={this.state.initialData.agreement_ru} />
                                    </TabPane>
                                    {
                                        this.state.haveOrphan
                                            ? <>
                                                <TabPane key="4" tab="LV (Aizbildnim)" forceRender>
                                                    <TextEditor lang="orphan_agreement_lv" returnData={this.agreementsHandler} data={this.state.initialData.orphan_agreement_lv} />
                                                </TabPane>
                                                <TabPane key="5" tab="EN (Trustee)" forceRender>
                                                    <TextEditor lang="orphan_agreement_en" returnData={this.agreementsHandler} data={this.state.initialData.orphan_agreement_en} />
                                                </TabPane>
                                                <TabPane key="6" tab="RU (Опекуну)" forceRender>
                                                    <TextEditor lang="orphan_agreement_ru" returnData={this.agreementsHandler} data={this.state.initialData.orphan_agreement_ru} />
                                                </TabPane>
                                            </>
                                            : <></>
                                    }
                                </Tabs>
                            </Col>
                        </>
                        : this.props.url === "/rest/study-groups-status"
                            ? <Col span={3}>
                                <Form.Item name="icon_color" label="Krāsa">
                                    <Input type="color" />
                                </Form.Item>
                            </Col>
                            : this.props.url === "/rest/cities"
                                ? <>
                                    <Col>
                                        <p>Geogrāfiskās koordinātas</p>
                                        <Form.Item label="Latitude" name="lat">
                                            <Input />
                                        </Form.Item>
                                        <Form.Item label="Longitude" name="lng">
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col>
                                        <Form.Item label="WEB" name="web">
                                            <Select style={{ width: 120 }} allowClear>
                                                <Select.Option value={0}>Nē</Select.Option>
                                                <Select.Option value={1}>Jā</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col>
                                        <Form.Item valuePropName="checked" name="has_exam" label="ir CSDD eksāmens">
                                            <Checkbox onChange={(e: any) => this.setState({ hasCsddExam: e.target.checked })} />
                                        </Form.Item>
                                    </Col>
                                    {
                                        this.state.hasCsddExam
                                            ? <></>
                                            : <Col>
                                                <Form.Item
                                                    style={{ width: 250 }}
                                                    name="near_exam_cities"
                                                    label="Tuvākā pilsēta"
                                                    initialValue={this.state.initialData.city_near[0]?.exam_city_id}
                                                >
                                                    <Select>
                                                        {
                                                            this.state.examCities?.map((city: any) => {
                                                                return <Select.Option key={city.id} value={city.id}>{city.value}</Select.Option>
                                                            })
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                    }
                                    <Col>
                                        <Form.Item valuePropName="checked" name="same_instructor_exam" label="Eksāmens pie sava instruktora">
                                            <Checkbox />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12} style={{ marginBottom: 8 }}>
                                        <Collapse>
                                            <Collapse.Panel header='lv' key='lv' forceRender>
                                                <Form.Item name="info_lv" label="Papildus informācija" >
                                                    <Input.TextArea autoSize={{ maxRows: 3, minRows: 3 }} />
                                                </Form.Item>
                                            </Collapse.Panel>
                                            <Collapse.Panel header='en' key='en' forceRender>
                                                <Form.Item name="info_en" label="Papildus informācija" >
                                                    <Input.TextArea autoSize={{ maxRows: 3, minRows: 3 }} />
                                                </Form.Item>
                                            </Collapse.Panel>
                                            <Collapse.Panel header='ru' key='ru' forceRender>
                                                <Form.Item name="info_ru" label="Papildus informācija" >
                                                    <Input.TextArea autoSize={{ maxRows: 3, minRows: 3 }} />
                                                </Form.Item>
                                            </Collapse.Panel>
                                        </Collapse>
                                    </Col>
                                </>
                                : <></>
                    }
                    {
                        this.props.url === "/rest/study-groups-status"
                            ? <>
                                <Col span={6}>
                                    <Form.Item valuePropName="checked" name="approve_study_group_status" label="Apstiprināta grupa (status)">
                                        <Checkbox />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item valuePropName="checked" name="new_group" label="Jaunas grupa (status)">
                                        <Checkbox />
                                    </Form.Item>
                                </Col>
                            </>
                            : <></>
                    }
                </Row>
                <Row gutter={20}>
                    <Col>
                        <Button type="primary" htmlType="submit">Saglabāt</Button>
                    </Col>
                </Row>
            </Form>
        </Card> : <Card><Skeleton /></Card>;
    }
}

export type NewOptionPageProps = RouteComponentProps & {
    title: string,
    url: string
}

export type NewOptionPageState = {
    checkedExam: boolean,
    checkedTestDrive: boolean,
    hasCsddExam: boolean,
    haveOrphan: boolean,
    haveStudyFrequency: boolean,
    pmp: boolean,
    examCities: [],
    categories: [],
    agreements: {},
};

export class BaseNewOptionPage extends React.Component<NewOptionPageProps, NewOptionPageState> {
    constructor(props: NewOptionPageProps) {
        super(props);

        this.state = {
            checkedExam: false,
            checkedTestDrive: false,
            hasCsddExam: false,
            haveOrphan: false,
            haveStudyFrequency: false,
            pmp: false,
            examCities: [],
            categories: [],
            agreements: {},
        };
    }


    onFinish = (values: any) => {
        console.log(values)
        if (this.props.url === "/rest/languages") {
            const isoEntry = isoArr.find((el: any) => el.name === values.value) as any;
            values.iso = isoEntry?.code;
            values.value_lv = isoEntry?.value_lv;
            values.value_en = isoEntry?.value_en;
            values.value_ru = isoEntry?.value_ru;
        }
        if (this.props.url === "/rest/cities") {
            values.near_exam_cities = [values.near_exam_cities]
        }
        if (this.props.url === "/rest/categories") {
            values = {
                ...values,
                ...this.state.agreements,
                have_orphan: values.have_orphan ? 1 : 0,
                have_study_frequency: values.have_study_frequency ? 1 : 0,
                priority: values.priority || 0,
                pmp: values.pmp ? 1 : 0,
                driving_options: values.driving_options ? 1 : 0,
                test_drive: values.test_drive ? 1 : 0,
                full_eval: values.full_eval ? 1 : 0,
            }
        }
        values.new_group = values.new_group ? 1 : 0;
        values.web = values.web ? 1 : 0;
        values.exam = values.exam ? 1 : 0;
        values.has_exam = this.state.hasCsddExam ? 1 : 0;
        values.same_instructor_exam = values.same_instructor_exam ? 1 : 0;
        values.icon_color = values.icon_color ? values.icon_color.substring(1) : undefined;
        http.post(this.props.url, values).then((response: any) => {
            this.props.history.replace("/options/settings");
        })
    };

    onFinishFailed = () => { };

    componentDidMount() {
        http.get(`${this.props.url}`).then((response: any) => {
            console.log(response.data.data)
            this.setState({
                categories: response.data.data.filter((item: any) => !item.exam),
                examCities: response.data.data.filter((item: any) => item.has_exam)
            })
        })
    }

    componentDidUpdate = (prevProps: any, prevState: any) => {
        console.log(prevState)
    }

    agreementsHandler = (key: any, data: any) => {
        this.setState({
            agreements: {
                ...this.state.agreements,
                [key]: data
            }
        })
    }

    render() {
        return <Card title={<strong>{this.props.title}</strong>}>
            <Form
                name="basic"
                // layout="vertical"
                onFinish={this.onFinish}
                onFinishFailed={this.onFinishFailed}
            >
                <Row gutter={20}>
                    <Col>
                        <Form.Item name="value" required rules={[{ required: true, whitespace: true, message: "Obligāts lauks" }]}>
                            {this.props.url === "/rest/languages"
                                ? <Select showSearch style={{ width: 200 }}>
                                    {isoArr.map((lang: any) => {
                                        return <Select.Option key={lang.code} value={lang.name}>{lang.name}</Select.Option>
                                    })}
                                </Select>
                                : <Input />}
                        </Form.Item>
                    </Col>
                    {this.props.url === "/rest/categories"
                        ? <>
                            <Col>
                                <Form.Item valuePropName="checked" name="pmp" label="PMP">
                                    <Checkbox value={this.state.pmp} onChange={(e: any) => this.setState({ pmp: e.target.checked })} />
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item valuePropName="checked" name="exam" label="Eksāmens">
                                    <Checkbox value={this.state.checkedExam} onChange={(e: any) => this.setState({ checkedExam: e.target.checked })} />
                                </Form.Item>
                            </Col>
                            {
                                this.state.checkedExam
                                    ? <Col span={6}>
                                        <Form.Item name="exam_category" label="Eksāmena kategorija">
                                            <Select>
                                                {this.state.categories.map((cat: any) => {
                                                    return <Select.Option key={cat.id} value={cat.id}>{cat.value}</Select.Option>
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    : <><Col>
                                        <Form.Item name="min_driving_lessons" label="Minimālais braukšanas nodarbību daudzums">
                                            <Input style={{ width: 50 }} />
                                        </Form.Item>
                                    </Col>
                                        <Col>
                                            <Form.Item valuePropName="checked" name="web" label="Rādīt WEB">
                                                <Checkbox />
                                            </Form.Item>
                                        </Col>
                                    </>

                            }
                            <Col>
                                <Form.Item valuePropName="checked" name="test_drive" label="Testa brauciena kategorija">
                                    <Checkbox value={this.state.checkedTestDrive} onChange={(e: any) => this.setState({ checkedTestDrive: e.target.checked })} />
                                </Form.Item>
                            </Col>
                            {
                                this.state.checkedTestDrive
                                && <Col span={6}>
                                    <Form.Item name="test_for_category" label="Testa brauciens kategorijai">
                                        <Select>
                                            {this.state.categories.map((cat: any) => {
                                                return <Select.Option key={cat.id} value={cat.id}>{cat.value}</Select.Option>
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            }
                            <Col>
                                <Form.Item name="daily_driving_limit" label="Dienas braukšanu limits">
                                    <Input style={{ width: 50 }} />
                                </Form.Item>
                                <Form.Item valuePropName="checked" name="full_eval" label="Padziļinātā vērtēšana">
                                    <Checkbox style={{ width: 50 }} />
                                </Form.Item>
                                <Form.Item valuePropName="checked" name="driving_options" label="Braukšanu opcijas pie slota">
                                    <Checkbox style={{ width: 50 }} />
                                </Form.Item>
                            </Col>
                            <Col span={12} style={{ marginBottom: 8 }}>
                                <Collapse>
                                    <Collapse.Panel header='lv' key='lv' forceRender>
                                        <Form.Item name="info_lv" label="Papildus informācija" >
                                            <Input.TextArea autoSize={{ maxRows: 3, minRows: 3 }} />
                                        </Form.Item>
                                    </Collapse.Panel>
                                    <Collapse.Panel header='en' key='en' forceRender>
                                        <Form.Item name="info_en" label="Papildus informācija" >
                                            <Input.TextArea autoSize={{ maxRows: 3, minRows: 3 }} />
                                        </Form.Item>
                                    </Collapse.Panel>
                                    <Collapse.Panel header='ru' key='ru' forceRender>
                                        <Form.Item name="info_ru" label="Papildus informācija" >
                                            <Input.TextArea autoSize={{ maxRows: 3, minRows: 3 }} />
                                        </Form.Item>
                                    </Collapse.Panel>
                                </Collapse>
                            </Col>
                            <Col>
                                <Form.Item name="priority" label="WEB prioritāte" >
                                    <Input defaultValue={0} style={{ width: 100 }} type="number" min={0} step={1} />
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item valuePropName="checked" name="have_orphan" label="Var būt nepieciešams Aizgādnis" >
                                    <Checkbox onChange={(e: any) => this.setState({ haveOrphan: e.target.checked })} />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Card>
                                    <Row>
                                        <Form.Item
                                            style={{ marginRight: 30 }}
                                            name="elearning_module_exam_fee"
                                            label="E-apmācības moduļa atkārtošanas cena"
                                        >
                                            <Input style={{ width: 80 }} type="number" min={0.00} step={0.01} />
                                        </Form.Item>
                                        <Form.Item
                                            style={{ marginRight: 30 }}
                                            valuePropName="checked"
                                            name="have_study_frequency"
                                            label="Teorijas nodarbību ierobežojums"
                                        >
                                            <Checkbox onChange={(e: any) => this.setState({ haveStudyFrequency: e.target.checked })} />
                                        </Form.Item>
                                        {
                                            this.state.haveStudyFrequency
                                                ? <Form.Item name="study_frequency" label="Daudzums nedēļā" >
                                                    <Input defaultValue={1} style={{ width: 100 }} type="number" min={1} step={1} />
                                                </Form.Item>
                                                : <></>
                                        }
                                    </Row>
                                </Card>
                            </Col>
                            <Col span={24}>
                                <Tabs defaultActiveKey="1">
                                    <TabPane key="1" tab="LV" forceRender>
                                        <TextEditor lang="agreement_lv" returnData={this.agreementsHandler} />
                                    </TabPane>
                                    <TabPane key="2" tab="EN" forceRender>
                                        <TextEditor lang="agreement_en" returnData={this.agreementsHandler} />
                                    </TabPane>
                                    <TabPane key="3" tab="RU" forceRender>
                                        <TextEditor lang="agreement_ru" returnData={this.agreementsHandler} />
                                    </TabPane>
                                    {
                                        this.state.haveOrphan
                                            ? <>
                                                <TabPane key="4" tab="LV (Aizbildnim)" forceRender>
                                                    <TextEditor lang="orphan_agreement_lv" returnData={this.agreementsHandler} />
                                                </TabPane>
                                                <TabPane key="5" tab="EN (Trustee)" forceRender>
                                                    <TextEditor lang="orphan_agreement_en" returnData={this.agreementsHandler} />
                                                </TabPane>
                                                <TabPane key="6" tab="RU (Опекуну)" forceRender>
                                                    <TextEditor lang="orphan_agreement_ru" returnData={this.agreementsHandler} />
                                                </TabPane>
                                            </>
                                            : <></>
                                    }
                                </Tabs>
                            </Col>
                        </>
                        : this.props.url === "/rest/study-groups-status"
                            ? <Col span={3}>
                                <Form.Item name="icon_color" label="Krāsa">
                                    <Input type="color" />
                                </Form.Item>
                            </Col>
                            : this.props.url === "/rest/cities"
                                ? <>
                                    <Col>
                                        <p>Geogrāfiskās koordinātas</p>
                                        <Form.Item label="Latitude" name="lat">
                                            <Input />
                                        </Form.Item>
                                        <Form.Item label="Longitude" name="lng">
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col>
                                        <Form.Item label="WEB" name="web">
                                            <Select style={{ width: 120 }} allowClear>
                                                <Select.Option value={0}>Nē</Select.Option>
                                                <Select.Option value={1}>Jā</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col>
                                        <Form.Item valuePropName="checked" name="has_exam" label="ir CSDD eksāmens">
                                            <Checkbox onChange={(e: any) => this.setState({ hasCsddExam: e.target.checked })} />
                                        </Form.Item>
                                    </Col>
                                    {
                                        this.state.hasCsddExam
                                            ? <></>
                                            : <Col>
                                                <Form.Item
                                                    style={{ width: 250 }}
                                                    name="near_exam_cities"
                                                    label="Tuvākā pilsēta"
                                                >
                                                    <Select>
                                                        {
                                                            this.state.examCities?.map((city: any) => {
                                                                return <Select.Option key={city.id} value={city.id}>{city.value}</Select.Option>
                                                            })
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                    }
                                    <Col>
                                        <Form.Item valuePropName="checked" name="same_instructor_exam" label="Eksāmens pie sava instruktora">
                                            <Checkbox />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12} style={{ marginBottom: 8 }}>
                                        <Collapse>
                                            <Collapse.Panel header='lv' key='lv' forceRender>
                                                <Form.Item name="info_lv" label="Papildus informācija" >
                                                    <Input.TextArea autoSize={{ maxRows: 3, minRows: 3 }} />
                                                </Form.Item>
                                            </Collapse.Panel>
                                            <Collapse.Panel header='en' key='en' forceRender>
                                                <Form.Item name="info_en" label="Papildus informācija" >
                                                    <Input.TextArea autoSize={{ maxRows: 3, minRows: 3 }} />
                                                </Form.Item>
                                            </Collapse.Panel>
                                            <Collapse.Panel header='ru' key='ru' forceRender>
                                                <Form.Item name="info_ru" label="Papildus informācija" >
                                                    <Input.TextArea autoSize={{ maxRows: 3, minRows: 3 }} />
                                                </Form.Item>
                                            </Collapse.Panel>
                                        </Collapse>
                                    </Col>
                                </>
                                : <></>
                    }
                    {
                        this.props.url === "/rest/study-groups-status"
                            ? <>
                                <Col span={6}>
                                    <Form.Item valuePropName="checked" name="approve_study_group_status" label="Apstiprināta grupa (status)">
                                        <Checkbox />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item valuePropName="checked" name="new_group" label="Jaunas grupa (status)">
                                        <Checkbox />
                                    </Form.Item>
                                </Col>
                            </>
                            : <></>
                    }
                </Row>
                <Row gutter={20}>
                    <Col>
                        <Button type="primary" htmlType="submit">Pievienot</Button>
                    </Col>
                </Row>
            </Form>
        </Card>;
    }
}

export type OptionsOutlinePageProps = RouteComponentProps & {
};

export type OptionsOutlinePageState = {
};

export class BaseOptionsOutlinePage extends React.Component<OptionsOutlinePageProps, OptionsOutlinePageState> {
    constructor(props: OptionsOutlinePageProps) {
        super(props);
    }

    render() {
        return (
            <>
                <Row gutter={20}>
                    <Col flex={1}>
                        <Card bodyStyle={{ padding: 0 }}>
                            <ClassifierOptionTable
                                title="Valoda"
                                url="/rest/languages"
                                addNew="/options/settings/languages/new"
                                editBase="/options/settings/languages/edit"
                            />
                        </Card>
                    </Col>
                    <Col flex={1}>
                        <Card bodyStyle={{ padding: 0 }}>
                            <ClassifierOptionTable
                                title="Pilsēta"
                                url="/rest/cities"
                                addNew="/options/settings/cities/new"
                                editBase="/options/settings/cities/edit"
                            />
                        </Card>
                    </Col>

                    <Col flex={1}>
                        <Card bodyStyle={{ padding: 0 }}>
                            <ClassifierOptionTable
                                title="Kategorija"
                                url="/rest/categories"
                                addNew="/options/settings/categories/new"
                                editBase="/options/settings/categories/edit"
                            />
                        </Card>
                    </Col>
                    <Col flex={1}>
                        <Card bodyStyle={{ padding: 0 }} style={{ marginBottom: 15 }}>
                            <StudyServiceTypes />
                        </Card>
                        <Card style={{ marginBottom: 15 }}>
                            <h4>Globālie skolas uzstādījumi</h4>
                            <SchoolGlobalSettings />
                        </Card>
                        <Card style={{ marginBottom: 15 }}>
                            <h4>Globālie braukšanas uzstādījumi</h4>
                            <DrivingGlobalSettings />
                        </Card>
                        <Card style={{ marginBottom: 15 }}>
                            <h4>Globālie atlaižu uzstādījumi</h4>
                            <GlobalDiscountsSettings />
                        </Card>
                        <Card bodyStyle={{ padding: 0 }}>
                            <ClassifierOptionTable
                                title="Status"
                                url="/rest/study-groups-status"
                                addNew="/options/settings/study-groups/new"
                                editBase="/options/settings/study-groups/edit"
                            />
                        </Card>
                        <Card bodyStyle={{ padding: 0 }} style={{ marginTop: 15 }}>
                            <ClassifierOptionTable
                                title="Apmācību veids"
                                url="/rest/study-groups-types"
                                addNew="/options/settings/study-group-types/new"
                                editBase="/options/settings/study-group-types/edit"
                            />
                        </Card>
                        <Card style={{ marginTop: 15 }}>
                            <h4>CSDD piekļuve</h4>
                            <CSDDCredentials />
                        </Card>
                    </Col>
                </Row>
            </>
        );
    }
}

export const OptionsOutlinePage = withRouter(BaseOptionsOutlinePage);
export const NewOptionPage = withRouter(BaseNewOptionPage);
export const EditOptionPage = withRouter(BaseEditOptionPage);

export const OptionsPage = withRouter((props: any) => {
    return (
        <Layout>
            <Content style={{ padding: 20 }}>
                <Switch>
                    <Route exact path={props.match.path}>
                        <OptionsOutlinePage />
                    </Route>
                    <Route exact path={`${props.match.path}/languages/new`}>
                        <NewOptionPage title="Jauna valoda" url="/rest/languages" />
                    </Route>
                    <Route exact path={`${props.match.path}/categories/new`}>
                        <NewOptionPage title="Jauna kategorija" url="/rest/categories" />
                    </Route>
                    <Route exact path={`${props.match.path}/cities/new`}>
                        <NewOptionPage title="Jauna pilsēta" url="/rest/cities" />
                    </Route>
                    <Route exact path={`${props.match.path}/study-groups/new`}>
                        <NewOptionPage title="Jauns grupu status" url="/rest/study-groups-status" />
                    </Route>
                    <Route exact path={`${props.match.path}/study-group-types/new`}>
                        <StudyTypes title="Jauns grupu status" url="/rest/study-groups-status" />
                    </Route>
                    <Route exact path="/options/settings/service-types/new">
                        <StudyServiceTypesForm />
                    </Route>
                    <Route exact path={`${props.match.path}/study-group-types/edit/:id`}>
                        <StudyTypes title="Jauns grupu status" url="/rest/study-groups-status" />
                    </Route>
                    <Route exact path={`${props.match.path}/cities/edit/:id`}>
                        <EditOptionPage title="Labot pilsētu" url="/rest/cities" />
                    </Route>
                    <Route exact path={`${props.match.path}/categories/edit/:id/exam-prices`}>
                        <ExamPrices />
                    </Route>
                    <Route exact path={`${props.match.path}/categories/edit/:id`}>
                        <EditOptionPage title="Labot kategoriju" url="/rest/categories" />
                    </Route>
                    <Route exact path={`${props.match.path}/languages/edit/:id`}>
                        <EditOptionPage title="Labot valodu" url="/rest/languages" />
                    </Route>
                    <Route exact path={`${props.match.path}/study-groups/edit/:id`}>
                        <EditOptionPage title="Labot grupu statusu" url="/rest/study-groups-status" />
                    </Route>
                    <Route exact path="/options/settings/service-types/edit/:id">
                        <StudyServiceTypesForm />
                    </Route>
                </Switch>
            </Content>
        </Layout>
    );
});