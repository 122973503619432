import { Button, Col, DatePicker, Input, message, Modal, Row, Select, TimePicker } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { http } from "../../../../helpers/http";

type SlotType = {
    exam_date: string | null;
    time_from: string | null;
    time_to: string | null;
    how_many_exams: number;
    languages: string[];
    enabled: number;
    price: number | null;
    comment: string | null;
};

export const AddSlotModal = (props: any) => {
    const { slot, visible, setVisible, languages, updateSlots, day } = props;
    const [newSlot, setNewSlot] = useState({
        exam_date: slot?.exam_date ? slot?.exam_date : null,
        time_from: slot?.time_from ? slot?.time_from : null,
        time_to: slot?.time_to ? slot?.time_to : null,
        how_many_exams: 1,
        languages: slot?.languages ? slot?.languages : [],
        enabled: 1,
        price: null,
        comment: null
    });
    const [newSlots, setNewSlots] = useState<SlotType[]>([
        {
            exam_date: slot?.exam_date ? slot?.exam_date : day ? moment(day).format("YYYY-MM-DD") : null,
            time_from: slot?.time_from ? slot?.time_from : null,
            time_to: slot?.time_to ? slot?.time_to : null,
            how_many_exams: 1,
            languages: slot?.languages ? slot?.languages : [],
            enabled: 1,
            price: null,
            comment: null
        }
    ]);

    const addNewSlot = () => {
        setNewSlots((prev) => {
            const lastSlot = prev.length > 0 ? prev[prev.length - 1] : prev[0];

            return [
                ...prev,
                {
                    exam_date: lastSlot.exam_date,
                    time_from: lastSlot.time_from,
                    time_to: lastSlot.time_to,
                    how_many_exams: lastSlot.how_many_exams,
                    languages: [...lastSlot.languages], 
                    enabled: lastSlot.enabled,
                    price: lastSlot.price,
                    comment: lastSlot.comment
                },
            ];
        });
    };

    const deleteNewSlot = (index: number) => {
        if (newSlots.length === 1) {
            return;
        }
        setNewSlots((prev: any) => {
            return prev.filter((slot: any, i: number) => {
                return i !== index;
            })
        })
    }

    const createNewSlot = () => {
        const disabled = newSlots.some((slot: any) => {
            return slot.exam_date === null || slot.time_from === null || slot.time_to === null || slot.languages.length === 0
        })
        if (disabled) {
            return;
        }
        // saveNewSlotsBulk();
        http.post(`rest/theory_exam_slots`, newSlots).then((response: any) => {
            setVisible(false);
            updateSlots();
        })
    }

    const saveNewSlotsBulk = () => {
        console.log("Bulky sloti bomb dropping below");
        console.log(newSlots);
        http.post(`rest/theory_exam_slots`, { slots: newSlots }).then((response: any) => {
            setVisible(false);
            updateSlots();
        }).catch((error: any) => {
            message.error("Kļūda, mēģiniet vēlreiz");
            setVisible(false);
        });
    }

    useEffect(() => {
        setNewSlot({
            exam_date: slot?.exam_date ? slot?.exam_date : day ? moment(day).format("YYYY-MM-DD") : null,
            time_from: slot?.time_from ? slot?.time_from : null,
            time_to: slot?.time_to ? slot?.time_to : null,
            how_many_exams: 1,
            languages: slot?.languages ? slot?.languages : [],
            enabled: 1,
            price: null,
            comment: null
        })
    }, [slot]);

    useEffect(() => {
        if (visible) {
            setNewSlots([
                {
                    exam_date: slot?.exam_date ? slot?.exam_date : day ? moment(day).format("YYYY-MM-DD") : null,
                    time_from: slot?.time_from ? slot?.time_from : null,
                    time_to: slot?.time_to ? slot?.time_to : null,
                    how_many_exams: 1,
                    languages: slot?.languages ? slot?.languages : [],
                    enabled: 1,
                    price: null,
                    comment: null
                }
            ])
        }

    }, [visible])

    return <Modal
        visible={visible}
        onCancel={() => setVisible(false)}
        // onOk={createNewSlot}
        width={800}
        footer={
            <Row justify="end">
                <Button
                    onClick={() => setVisible(false)}
                >
                    Atcelt
                </Button>
                <Button
                    type="primary"
                    onClick={saveNewSlotsBulk}
                    disabled={
                        newSlots.some((slot: any) => {
                            return slot.exam_date === null || slot.time_from === null || slot.time_to === null || slot.languages.length === 0
                        })
                    }
                >
                    Saglabāt
                </Button>
            </Row>
        }
    >
        {
            newSlots.map((slot: any, index: number) => {
                return <Row key={index} align="bottom">
                    <Col>
                        <b>Datums: </b><br />
                        <DatePicker
                            clearIcon={false}
                            style={{ width: 170, marginRight: 8 }}
                            format={"DD.MM.YYYY"}
                            value={slot.exam_date === null ? null : moment(slot.exam_date, "YYYY-MM-DD")}
                            onChange={(e: any) => {
                                setNewSlots((prev: any) => {
                                    return prev.map((slot: any, i: number) => {
                                        if (i === index) {
                                            return {
                                                ...slot,
                                                exam_date: moment(e).format("YYYY-MM-DD")
                                            }
                                        }
                                        return slot;
                                    })
                                })
                            }}
                        />
                    </Col>
                    <Col>
                        <b>Laika periods: </b><br />
                        <TimePicker.RangePicker
                            clearIcon={false}
                            style={{ width: 170, marginRight: 8 }}
                            format={"HH:mm"}
                            value={slot.time_from === null ? null : [moment(slot.time_from, "HH:mm"), moment(slot.time_to, "HH:mm")]}
                            onChange={(e: any) => {
                                setNewSlots((prev: any) => {
                                    return prev.map((slot: any, i: number) => {
                                        if (i === index) {
                                            return {
                                                ...slot,
                                                time_from: moment(e[0]).format("HH:mm"),
                                                time_to: moment(e[1]).format("HH:mm")
                                            }
                                        }
                                        return slot;
                                    })
                                })
                            }}
                        />
                    </Col>
                    {/* <Col>
                    <b>Sloti: </b><br />
                    <Input
                        style={{ width: 60, marginRight: 8, marginBottom: 20 }}
                        type="number"
                        min={1}
                        max={9}
                        value={newSlot.how_many_exams}
                        onChange={(e: any) => {
                            e.persist()
                            setNewSlot((prev: any) => {
                                return {
                                    ...prev,
                                    how_many_exams: e.target.value
                                }
                            })
                        }}
                    />
                </Col> */}
                        <Col>
                        <b>Slots:</b><br />
                        <Input
                            type="number"
                            min={1}
                            value={slot.how_many_exams}
                            onChange={(e) =>
                                setNewSlots((prev: SlotType[]) => prev.map((s, i) =>
                                    i === index ? { ...s, how_many_exams: parseInt(e.target.value) || 1 } : s
                                ))
                            }
                            style={{ width: 60, marginRight: 8 }}
                        />
                    </Col>
                    <Col>
                        <b>Valodas: </b><br />
                        <Select
                            style={{ width: 167, marginRight: 8 }}
                            mode="multiple"
                            value={slot.languages}
                            onChange={(e: any) => {
                                setNewSlots((prev: any) => {
                                    return prev.map((slot: any, i: number) => {
                                        if (i === index) {
                                            return {
                                                ...slot,
                                                languages: e
                                            }
                                        }
                                        return slot;
                                    })
                                })
                            }}
                        >
                            {
                                languages?.map((lang: any) => {
                                    return <Select.Option key={lang?.id} value={lang?.id}>{lang?.iso?.toUpperCase()}</Select.Option>
                                })
                            }

                        </Select>
                    </Col>          
                    <Col style={{ display: "flex", alignItems: "center" }}>
                    {index === newSlots.length - 1 && (
                        <Button 
                            type="primary" 
                            onClick={addNewSlot}
                        >
                            +
                        </Button>
                    )}
                        {index > 0 && (
                            <Button 
                                type="ghost" 
                                danger onClick={() => deleteNewSlot(index)} 
                                style={{ marginLeft: 8 }}
                            >
                                Delete
                            </Button>
                        )}
                    </Col>       
                </Row>
            })
        }
    </Modal>
}