import { Button, Card, Col, Layout, PageHeader, Row, Select } from "antd";
import { AxiosResponse } from "axios";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { http } from "../../helpers/http";

export const OnlineStatsLayout = () => {
    const history = useHistory();
    const [city, setCity] = useState(-1);
    const [category, setCategory] = useState(-1);
    const [language, setLanguage] = useState(-1);
    const [cities, setCities] = useState([] as any);
    const [categories, setCategories] = useState([] as any);
    const [languages, setLanguages] = useState([] as any);
    const [stats, setStats] = useState(undefined as any);
    const [loading, setLoading] = useState(false);

    const fetchCategories = () => {
        http.get(`rest/categories`).then((res: AxiosResponse) => {
            setCategories(res.data.data)
        })
    }

    const fetchCities = () => {
        http.get(`rest/cities`).then((res: AxiosResponse) => {
            setCities(res.data.data)
        })
    }

    const fetchLanguages = () => {
        http.get(`rest/languages`).then((res: AxiosResponse) => {
            setLanguages(res.data.data)
        })
    }

    const fetchStats = () => {
        setLoading(true);
        const filters = {
            city: city > 0 ? [city] : undefined,
            category: category > 0 ? [category] : undefined,
            language: language > 0 ? [language] : undefined
        }
        http.get(`rest/get-module-avg`, {
            params: {filters}
        }).then((res: AxiosResponse) => {
            setLoading(false);
            console.log(res)
            setStats(res.data)
        }).catch(() => {
            setLoading(false);
        });
    }

    useEffect(() => {
        fetchCities();
        fetchCategories();
        fetchLanguages();
    }, []);

    return <Layout>
        <PageHeader
            title="Online apmācības statistika"
            style={{ backgroundColor: "#FFFFFF" }}
            onBack={history.goBack}
        />
        <Card style={{ margin: 20 }}>
            <h2 style={{ textAlign: "center" }}>
                Kopsavilkums
            </h2>
            <Row justify="center">
                <Col span={12}>
                    <Card loading={loading}>
                        <Row justify="center">
                            <Select
                                value={city}
                                style={{ width: 200, marginBottom: 20, marginRight: 10 }}
                                onChange={e => {
                                    setCity(e)
                                }}
                            >
                                <Select.Option value={-1}>
                                    Visas pilsētas
                                </Select.Option>
                                {
                                    cities.map((city: any) => {
                                        return <Select.Option value={city.id} key={city.id}>
                                            {city.value}
                                        </Select.Option>
                                    })
                                }
                            </Select>
                            <Select
                                value={category}
                                style={{ width: 200, marginBottom: 20, marginRight: 10 }}
                                onChange={e => {
                                    setCategory(e)
                                }}
                            >
                                <Select.Option value={-1}>
                                    Visas kategorijas
                                </Select.Option>
                                {
                                    categories.map((category: any) => {
                                        return <Select.Option value={category.id} key={category.id}>
                                            {category.value}
                                        </Select.Option>
                                    })
                                }
                            </Select>
                            <Select
                                value={language}
                                style={{ width: 200, marginBottom: 20, marginRight: 10 }}
                                onChange={e => {
                                    setLanguage(e)
                                }}
                            >
                                <Select.Option value={-1}>
                                    Visas valodas
                                </Select.Option>
                                {
                                    languages.map((language: any) => {
                                        return <Select.Option value={language.id} key={language.id}>
                                            {language.value}
                                        </Select.Option>
                                    })
                                }
                            </Select>
                            <Button
                                onClick={fetchStats}
                                type="primary"
                            >
                                Atlasīt
                            </Button>
                        </Row>
                        <Row justify="center" style={{ marginBottom: 10 }}>
                            <Col span={6} style={{ textAlign: "right", fontSize: 20 }}>
                                Kopā mācās:
                            </Col>
                            <Col span={6} style={{ textAlign: "center", fontSize: 20 }}>
                                <b>{stats === undefined ? "--" : stats.overall}</b>
                            </Col>
                        </Row>
                        <Row justify="center" style={{ marginBottom: 10 }}>
                            <Col span={6} style={{ textAlign: "right", fontSize: 20 }}>
                                Ātri:
                            </Col>
                            <Col span={6} style={{ textAlign: "center", fontSize: 20 }}>
                                <b>{stats === undefined ? "--" : stats.fast}</b>
                            </Col>
                        </Row>
                        <Row justify="center" style={{ marginBottom: 10 }}>
                            <Col span={6} style={{ textAlign: "right", fontSize: 20 }}>
                                Vidēji:
                            </Col>
                            <Col span={6} style={{ textAlign: "center", fontSize: 20 }}>
                                <b>{stats === undefined ? "--" : stats.medium}</b>
                            </Col>
                        </Row>
                        <Row justify="center" style={{ marginBottom: 20 }}>
                            <Col span={6} style={{ textAlign: "right", fontSize: 20 }}>
                                Lēni:
                            </Col>
                            <Col span={6} style={{ textAlign: "center", fontSize: 20 }}>
                                <b>{stats === undefined ? "--" : stats.slow}</b>
                            </Col>
                        </Row>
                        <Row justify="center" style={{ marginBottom: 20 }}>
                            <Col span={6} style={{ textAlign: "right", fontSize: 20, color: "red" }}>
                                Nav norādīta pilsēta:
                            </Col>
                            <Col span={6} style={{ textAlign: "center", fontSize: 20, color: "red" }}>
                                <b>{stats === undefined ? "--" : stats.without_city}</b>
                            </Col>
                        </Row>
                        <Row justify="center" style={{ marginBottom: 20 }}>
                            <Col span={12} style={{ textAlign: "center", fontSize: 20 }}>
                                <Link to={`/groups/online-stats/${city === -1 ? "all" : city}/${category === -1 ? "all" : category}/${language === -1 ? "all" : language}`}>
                                    <Button
                                        type="primary"
                                        ghost
                                        style={{ width: "100%" }}
                                    >
                                        Detalizēti
                                    </Button>
                                </Link>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </Card>
    </Layout>
}