import React, { useEffect, useState } from "react";
import { Button, Card, Checkbox, Col, DatePicker, Form, Input, message, Row, Select, Skeleton, Space, Table, Tabs, Tag, TimePicker, Typography } from "antd";
import { v4 as uuidv4 } from "uuid";
import { http } from "../../helpers/http";
//@ts-ignore
import ReactHtmlParser from "react-html-parser";

export const Agreements = (props: any) => {
    const { student, registration, group } = props;

    const [agreement, setAgreement] = useState(null as any);
    const [loading, setLoading] = useState(true);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [abodl, setAbodl] = useState(false); //abodl - agreement based on driving lessons
    const [abodlLoading, setAbodlLoading] = useState(true);

    const checkAgreements = async () => {
        await http.get(`/rest/check-agreement/${student.id}${group ? `?group=${group.id}` : ""}`).then((response: any) => {
            const agreement = response.data.data;
            if (agreement !== null) {
                const allAgreementKeys = Object.keys(agreement).filter((item: any) => {
                    return item.includes("agreement");
                })
                const allAgreements = allAgreementKeys.map((key: any) => {
                    return { [key]: agreement[key] }
                })
                setAgreement(allAgreements)
            }
            setLoading(false);
        })
    };

    const manipulateAgreement = () => {
        setButtonLoading(true);
        http.post(`/rest/manipulate-agreement/${student.id}`, {
            from_web: student.web_app_ref ? 1 : 0,
            new: agreement === null ? 1 : 0,
            group: group.id
        }).then((response: any) => {
            setButtonLoading(false);
            checkAgreements();
        });
    }

    const checkAbodl = async () => {
        setAbodlLoading(true);
        http.get(`rest/get-agreement-on-driving/${student.id}/${group.id}`).then((response: any) => {
            setAbodlLoading(false);
            setAbodl(response.data === 1);
        }).catch((error: any) => {
            setAbodlLoading(false);
            message.error("Kļūda ielādējot līguma veidu!")
        });
    }

    useEffect(() => {
        checkAgreements();
        checkAbodl();
    }, []);

    return (
        <Card
            title={<Space>
                <b>Studenta līgumi</b>
                {
                    agreement !== null
                        ? <Button
                            onClick={manipulateAgreement}
                            danger
                            loading={buttonLoading}
                        >
                            Pārģenerēt līgumu
                        </Button>
                        : Object.keys(registration).length > 0
                            ? <Button
                                onClick={manipulateAgreement}
                                danger
                                loading={buttonLoading}
                            >
                                Ģenerēt līgumu
                            </Button>
                            : <Tag color="red">Pirms līguma izveidošanas, ir jānosūta reģistrācijas pieprasījums</Tag>
                }
                <Row>
                    <Checkbox
                        style={{ marginRight: 10 }}
                        checked={abodl}
                        onChange={(e) => {
                            setAbodlLoading(true);
                            http.post(`rest/set-agreement-on-driving`, {
                                student: student.id,
                                group: group.id,
                                abodl: e.target.checked ? 1 : 0
                            }).then((response: any) => {
                                setAbodlLoading(false);
                                setAbodl(e.target.checked);
                                message.success("Līguma veids saglabāts!")
                            }).catch((error: any) => {
                                setAbodlLoading(false);
                                message.error("Kļūda!")
                            });
                        }}
                        disabled={abodlLoading}
                    />
                    <b>Jaunais līgums</b>
                </Row>
            </Space>}
        >
            {
                loading
                    ? <Skeleton />
                    : agreement !== null
                        ? <Tabs defaultActiveKey="1">
                            {
                                agreement.map((agreement: any, index: any) => {
                                    const key = Object.keys(agreement)[0];
                                    const language = key.split("_");
                                    return <Tabs.TabPane key={String(index + 1)} tab={language[language.length - 1].toUpperCase()}>
                                        <div className="agrement-wrap-box">
                                            {ReactHtmlParser(agreement[key])}
                                        </div>
                                    </Tabs.TabPane>
                                })
                            }
                        </Tabs>
                        : <b>Nav līgumu</b>
            }
        </Card>
    )
}
