import { Layout, Card, Table, Row, Col } from 'antd'
import React, { useEffect, useState } from 'react'
import DrivenStatsFilter from './DrivenStatsFilter'
import { http } from '../../helpers/http'
import { AxiosResponse } from 'axios'
// import overviewMock from './mock/response.json';
// import detailedMock from './mock/2response.json';

function DrivenStats() {
    const [filter, setFilter] = useState({
        period: null
    } as any);
    const [allData, setAllData] = useState([] as any);
    const [overviewData, setOverviewData] = useState([] as any);
    const [expandedRows, setExpandedRows] = useState([] as any);
    const [loading, setLoading] = useState(false);

    const fetchDrivingStats = (clearPeriod: boolean = false) => {
        setLoading(true);
    
        let fetchPeriod: any[];
        if (filter.period === null) {
            fetchPeriod = [undefined, undefined];
        } else {
            fetchPeriod = [filter.period[0].format("YYYY-MM-DD"), filter.period[1].format("YYYY-MM-DD")];
        }
        if (clearPeriod) fetchPeriod = [undefined, undefined];
    
        http.get(`rest/driving-outside-lessons`, {
            params: {
                from: fetchPeriod[0],
                to: fetchPeriod[1],
            }
        }).then((res: AxiosResponse) => {
            const detailedData = res.data;
    
            const groupedInstructors: Record<number, {
                instructor_id: number;
                instructor: string;
                totalDistance: number;
            }> = {};
    
            detailedData.forEach((item: any) => {
                if (!groupedInstructors[item.instructor_id]) {
                    groupedInstructors[item.instructor_id] = {
                        instructor_id: item.instructor_id,
                        instructor: item.instructor,
                        totalDistance: 0,
                    };
                }
                groupedInstructors[item.instructor_id].totalDistance += item.distance;
            });
    
            const groupedByInstructorAndDate: Record<number, Record<string, any[]>> = {};
    
            detailedData.forEach((item: any) => {
                const instructorId = item.instructor_id;
                const date = item.date_time_from.split(' ')[0];
    
                if (!groupedByInstructorAndDate[instructorId]) {
                    groupedByInstructorAndDate[instructorId] = {};
                }
                if (!groupedByInstructorAndDate[instructorId][date]) {
                    groupedByInstructorAndDate[instructorId][date] = [];
                }
    
                groupedByInstructorAndDate[instructorId][date].push(item);
            });
    
            setAllData(groupedByInstructorAndDate);
            setOverviewData(Object.values(groupedInstructors));
            setLoading(false);
        }).catch((err) => {
            console.error(err);
            setLoading(false);
        });
    };

    // mockingbirb data
    // const fetchDrivingStats = (clearPeriod: boolean = false) => {
    //     setLoading(true);

    //     let from: string | undefined;
    //     let to: string | undefined;

    //         if (!clearPeriod) {
    //     if (!filter.period) {
    //         setLoading(false);
    //         return;
    //     }
    //     from = filter.period[0].format("YYYY-MM-DD");
    //     to = filter.period[1].format("YYYY-MM-DD");
    // }

    //     const detailedData = detailedMock.filter((item) => {
    //     const tripDate = item.date_time_from.split(' ')[0];
    //     if (from && tripDate < from) return false;
    //     if (to && tripDate > to) return false;
    //     return true;
    //     });

    //     const overviewDataRaw = overviewMock.filter((item) => {
    //     if (from && item.trip_date < from) return false;
    //     if (to && item.trip_date > to) return false;
    //     return true;
    //     });

    //     if (detailedData.length === 0 || overviewDataRaw.length === 0) {
    //         setOverviewData([]);
    //         setAllData({});
    //         setLoading(false);
    //         return;
    //       }
    
    //     const instructorMap: Record<number, string> = {};
    //     detailedData.forEach(item => {
    //         instructorMap[item.instructor_id] = item.instructor;
    //     });
    
    //     const groupedInstructors: Record<number, { instructor_id: number, instructor: string, totalDistance: number }> = {};

    //     detailedData.forEach((item) => {
    //     if (!groupedInstructors[item.instructor_id]) {
    //         groupedInstructors[item.instructor_id] = {
    //         instructor_id: item.instructor_id,
    //         instructor: item.instructor,
    //         totalDistance: 0,
    //         };
    //     }
    //     groupedInstructors[item.instructor_id].totalDistance += item.distance;
    //     });

    //     const overviewDataMerged = Object.values(groupedInstructors);

    //     const groupedByInstructorAndDate: Record<number, Record<string, any[]>> = {};

    //     detailedData.forEach((item) => {
    //         const instructorId = item.instructor_id;
    //         const date = item.date_time_from.split(' ')[0]; 

    //         if (!groupedByInstructorAndDate[instructorId]) {
    //             groupedByInstructorAndDate[instructorId] = {};
    //         }
    //         if (!groupedByInstructorAndDate[instructorId][date]) {
    //             groupedByInstructorAndDate[instructorId][date] = [];
    //         }

    //         groupedByInstructorAndDate[instructorId][date].push(item);
    //     });

    //     setAllData(groupedByInstructorAndDate); 
    
    //     //setAllData(detailedData);
    //     setOverviewData(overviewDataMerged);
    //     setLoading(false);
    // };

    const search = () => {
        fetchDrivingStats();
    };

    const cancel = () => {
        setFilter({
            period: null
        });
        fetchDrivingStats(true);
    };



    return <Layout>
        <Card
            title="Instruktoru nobraukumi"
        >
            <DrivenStatsFilter
                onFilterChange={setFilter}
                filter={filter}
                search={search}
                cancel={cancel}
            />
            <Table
                loading={loading}
                rowKey={(record) => record.instructor_id}
                dataSource={overviewData}
                columns={[
                    {
                        title: 'Instruktors',
                        dataIndex: 'instructor',
                        key: 'instructor',
                    },
                    {
                        title: 'Nobraukums ārpus nodarbībām',
                        dataIndex: 'totalDistance',
                        key: 'totalDistance',
                        render: (text: any) => <div>{text / 1000} km</div>
                    }
                ]}
                pagination={false}
                expandable={{
                    rowExpandable: (record) => true,
                    expandedRowKeys: expandedRows,
                    expandedRowRender: (record: any) => {
                      const tripsByDate = allData[record.instructor_id] || {};
                  
                      const dataSource: {
                        date: string;
                        totalDistance: number;
                        trips: any[];
                      }[] = Object.entries(tripsByDate).map(([date, trips]) => {
                        const totalDistance = (trips as any[]).reduce((sum, trip) => sum + trip.distance, 0);
                        return {
                          date,
                          totalDistance,
                          trips: trips as any[],
                        };
                      });
                  
                      return (
                        <Table
                          columns={[
                            {
                              title: 'Diena',
                              dataIndex: 'date',
                              key: 'date',
                            },
                            {
                              title: 'Nobraukums',
                              dataIndex: 'totalDistance',
                              key: 'totalDistance',
                              render: (val) => `${(val / 1000).toFixed(2)} km`,
                            },
                          ]}
                          rowKey={(row: any) => row.date}
                          dataSource={dataSource}
                          pagination={false}
                          expandable={{
                            expandedRowRender: (dayRecord: any) => {
                              return (
                                <div>
                                  {dayRecord.trips.map((trip: any, index: number) => (
                                    <Card key={index} style={{ marginBottom: 10 }}>
                                    <Row>
                                      <Col span={24}>
                                        <p>
                                          <b>Laika posms starp nodarbībām: {trip.date_gap_from} - {trip.date_gap_to}</b>
                                        </p>
                                      </Col>
                                      <Col span={24}>
                                        <p>
                                          <b>{trip.date_time_from} - {trip.date_time_to}</b>
                                        </p>
                                      </Col>
                                      <Col span={24}>
                                        <Row style={{ flexWrap: 'nowrap' }}>
                                          <p style={{ color: "#f40606" }}>
                                            <b>{trip.distance / 1000} km</b>
                                          </p>
                                          <p style={{ marginLeft: 10 }}>
                                            <b>({trip.vehicle}, {trip.licence_plate})</b>
                                          </p>
                                        </Row>
                                      </Col>
                                      <Col span={24}>
                                        <p>
                                          <b>Sākuma punkts: {trip.start_location}; Beigu punkts: {trip.stop_location}</b>
                                        </p>
                                      </Col>
                                    </Row>
                                  </Card>
                                  ))}
                                </div>
                              );
                            },
                          }}
                        />
                      );
                    },
                    onExpand(expanded, record) {
                      if (expanded) {
                        setExpandedRows((prev: any) => [...prev, record.instructor_id]);
                      } else {
                        setExpandedRows((prev: any) =>
                          prev.filter((item: any) => item !== record.instructor_id)
                        );
                      }
                    },
                  }}
               
                
            />
        </Card>
    </Layout>
}

export default DrivenStats