import { Button, Card, Checkbox, Col, DatePicker, Form, Input, InputNumber, Layout, message, PageHeader, Row, Select, Space, Table, Tag } from "antd";
import { EditOutlined } from "@ant-design/icons";
import moment from "moment";
import React, { SyntheticEvent, useContext, useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { http } from "../../helpers/http";
import lodash from 'lodash';
import LoadingOutlined from "@ant-design/icons/lib/icons/LoadingOutlined";
import { EditLessonPrices } from "./EditLessonPrices";
import { HowManyLessonsToOneContext } from "../../helpers/HowManyLessonsToOneContext";

const PayoutForm = (props: any) => {
    const [form] = Form.useForm();
    const [sum, setSum] = useState(0);
    const [deduction, setDeduction] = useState(0);

    const layout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 8,
        },
    };

    const getInvoiceNumber = () => {
        let invoiceNumber = props.invoiceTemplate
            ?.replaceAll("{year}", moment().format("YYYY"))
            ?.replaceAll("{month}", moment().format("MM"))
        return invoiceNumber
    }

    const payoutHandler = (values: any) => {
        if (props.saving) {
            return;
        }

        const selectedIds = props.selectedLessons.map((lesson: any) => lesson.lesson_id);
        const date = values.date ? values.date : moment();
        const invoice_number = values.invoice_number && String(values.invoice_number).trim() || "";

        if ((selectedIds.length > 0) && (invoice_number)) {
            props.setSaving(true);
            http.post("/invoices/create-instructor-invoice", {
                ...values,
                date: date.format("YYYY-MM-DD"),
                instructor_id: props.instructor.id,
                selected_lesson_ids: selectedIds,
                premium: values.premium ? 1 : 0
            }).then(() => {
                setTimeout(() => { window.location.reload(); }, 1000);
            });
        } else if (!invoice_number) {
            message.error("Nav norādīts rēķina nr.");
        }
    }

    const calcSum = (arr: any, deduction: number) => {
        if (arr.length > 0) {
            const calc = (arr.map((lesson: any) => {
                let amount = 0;
                const timeSlot = lesson;
                const howManyLessons = props.howManyLessonsToOne.some((number: Number) => number === timeSlot.how_many_lessons) ? 1 : timeSlot.how_many_lessons;
                const seqNr = lesson.sequence_number;
                timeSlot.slot_prices.forEach((p: any) => {
                    if (p.position === "Instruktors") {
                        const rateArr = Number(p.rate) * (Number(p.rate_vat) / 100 + 1)
                        //   if (seqNr > 0 && Boolean(Number(p.is_external))) {
                        //     let currSeqNr = seqNr - howManyLessons;
                        //     while (currSeqNr < seqNr) {
                        //       const rate = currSeqNr >= p.extra_start_hours ? p.extra_rate : p.rate;
                        //       amount += Number(rate)
                        //       currSeqNr++;
                        //     }
                        //   } else {
                        //     amount += howManyLessons * p.rate_total;
                        //   }
                        amount += howManyLessons * rateArr
                    }
                });
                return amount;
            }).reduce((a: number, b: number) => a + b) - deduction).toFixed(2)
            console.log(calc);
            setSum(Number(calc));
        }
    }

    useEffect(() => {
        calcSum(props.lessons, deduction);
    }, [deduction, props.lessons]);

    useEffect(() => {
        if (props.period !== undefined && props.period !== null) {
            form.setFieldsValue({ period: moment(props.period[0]).format("DD.MM.YYYY") + " - " + moment(props.period[1]).format("DD.MM.YYYY") })
        }
    }, [props.period])

    const deductionHandler = (event: any) => {
        setDeduction(event);
    }

    return props.lessons.length > 0 ? (
        <Form
            form={form}
            {...layout}
            onFinish={payoutHandler}
            initialValues={{
                deduction: deduction,
                invoice_number: getInvoiceNumber()
            }}
            onChange={(event: SyntheticEvent) => {
                props.returnBonus(form.getFieldValue("bonus"))
            }}
        >
            <Form.Item name="invoice_number" label="Rēķina nr.:" required>
                <Input width={20} name="invoice_number" />
            </Form.Item>
            <Form.Item name="date" label="Rēķina datums:" initialValue={moment().startOf('month').add(9, "day")}>
                <DatePicker name="date" format="DD.MM.YYYY" />
            </Form.Item>
            <Form.Item
                name="period"
                label="Periods:"
                initialValue={
                    props.period !== undefined && props.period !== null
                        ? moment(props.period[0]).format("DD.MM.YYYY") + " - " + moment(props.period[1]).format("DD.MM.YYYY")
                        : ""
                }
            >
                <Input name="period" />
            </Form.Item>
            <Form.Item name="deduction" label="Atvilkuma summa EUR:">
                <InputNumber value={deduction} onChange={deductionHandler} />
            </Form.Item>
            <Form.Item name="deduction_reason" label="Atvilkuma pamatojums:">
                <Input.TextArea name="deduction_reason" autoSize={{ maxRows: 4, minRows: 4 }} style={{ width: 200 }} />
            </Form.Item>
            <Form.Item name="bonus" label="Prēmija">
                <InputNumber />
            </Form.Item>
            <Form.Item style={{}} name="premium" label="Prēmiju piešķirt visām mēneša nodarbībām" valuePropName="checked">
                <Checkbox />
            </Form.Item>
            <Form.Item label="Izmaksājamā summa EUR: ">
                <span style={{ fontWeight: "bold", fontSize: 16 }}>{sum.toString()}</span>
            </Form.Item>
            <Button loading={props.saving} style={{ margin: "0 0 0 226px" }} type="primary" htmlType="submit">{!props.saving ? "Izstādīt" : "Saglabā..."}</Button>
        </Form>
    ) : <></>
}

export const InstructorBalance = withRouter((props: any) => {
    const { howManyLessonsToOne } = useContext(HowManyLessonsToOneContext);
    const history = useHistory();
    const [selectedLessons, setSelection] = useState([] as Array<any>);
    //   const [data, setData] = useState([] as any);
    const [instructor, setInstructor] = useState({} as any);
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [period, setPeriod] = useState([
        moment().subtract(1, 'month').startOf('month'),
        moment().subtract(1, 'month').endOf('month')
    ] as any);
    const [pageSize, setPageSize] = useState(10 as any);
    const [bonus, setBonus] = useState(0);

    const StudentCell = (props: any) => {
        const lesson = props.lesson;
        const student = lesson.student;

        //   const [sequenceNumber, setSequenceNumber] = useState(null as any);
        //   const [loading, setLoading] = useState(false);

        //   function loadSequenceNumber() {
        //     setLoading(true);
        //     http.get(`/invoices/get-student-lesson-sequence/${lesson.id}`)
        //       .then((result) => {
        //         setSequenceNumber(result.data.data);
        //         setLoading(false);
        //       })
        //       .catch(() => {
        //         setLoading(false);
        //       })
        //   }

        let cSequenceNumber = null;
        // const timeSlot = lesson.lesson_time_slot;
        if (howManyLessonsToOne.some((number: Number) => number !== lesson.how_many_lessons) && lesson.sequence_number) {
            cSequenceNumber = `${lesson.sequence_number - (lesson.how_many_lessons - 1)}. - ${lesson.sequence_number}.`
        }

        return <Space>
            <span>{student}</span>
            <span>{cSequenceNumber}</span>
            {
                bonus > Number(lesson.school_max)
                && <Tag color="red">
                    Bonus lielāks par skolas likmi
                </Tag>
            }
        </Space>
    }

    const fetchBalance = async (period: any) => {
        setLoading(true)
        if (period === null) {
            await http.get(`/invoices/get-instructor-balance/${props.match.params.id}`)
                .then(result => {
                    console.log(result)
                    if (result.data.data === null) {
                        window.location.href = '/invoices';
                    }
                    setInstructor(result.data.data);
                    setLoading(false);
                });
        } else {
            await http.get(`/invoices/get-instructor-balance/${props.match.params.id}`, {
                params: {
                    start_period: moment(period[0], "DD.MM.YYYY").format("YYYY-MM-DD"),
                    end_period: moment(period[1], "DD.MM.YYYY").format("YYYY-MM-DD"),
                }
            })
                .then(result => {
                    console.log(result)
                    if (result.data.data === null) {
                        window.location.href = '/invoices';
                    }
                    setInstructor(result.data.data);
                    setLoading(false);
                });
        }
    }

    const columns = [
        {
            title: 'Datums',
            render: (lesson: any) => moment(lesson.date).format("DD.MM.YYYY")
        },
        {
            title: 'No',
            render: (lesson: any) => lesson.time_from
        },
        {
            title: 'Līdz',
            render: (lesson: any) => lesson.time_to
        },
        {
            title: 'Stundas',
            render: (timeSlot: any) => howManyLessonsToOne.some((number: Number) => number === timeSlot.how_many_lessons) ? 1 : timeSlot.how_many_lessons
        },
        {
            title: 'Students',
            render: (lesson: any, record: any) => {
                const regenerated = lesson.regenerated;
                return <>
                    <StudentCell lesson={lesson} bonus={bonus} />
                    {
                        regenerated !== null && regenerated !== undefined
                            ? <small style={{ color: "red" }}>Atteica, bet pieteicās cits students</small>
                            : ""
                    }
                </>
            },
            sorter: {
                compare: (a: any, b: any) => {
                    return `${a.student}`.localeCompare(
                        `${b.student}`
                    );
                }
            }
        },
        {
            title: 'Kategorija',
            render: (lesson: any) => lesson.category
        },
        // // {
        // //   title: 'Automašīna',
        // //   render: (lesson: any) => lesson.lesson_time_slot.instructor_service.vehicle
        // // },
        {
            title: 'Instruktora likme',
            render: (lesson: any) => {
                let amount = 0;
                let edited = null;
                // const timeSlot = lesson.lesson_time_slot;
                const howManyLessons = howManyLessonsToOne.some((number: Number) => number === lesson.how_many_lessons) ? 1 : lesson.how_many_lessons;
                const seqNr = lesson.sequence_number;
                lesson.slot_prices.forEach((p: any) => {
                    if (p.position === "Instruktors") {
                        const rateArr = Number(p.rate) * (Number(p.rate_vat) / 100 + 1)
                        edited = p.edited
                        // if (seqNr > 0 && Boolean(Number(p.is_external))) {
                        //     let currSeqNr = seqNr;
                        //     while (currSeqNr < seqNr + howManyLessons) {
                        //         // const rate = (currSeqNr >= p.extra_start_hours && p.extra_rate > 0) ? p.extra_rate : p.rate;

                        //         amount += (p.rate_total * (1 + p.rate_vat / 100))
                        //         currSeqNr++;
                        //     }
                        // } else {
                        //     amount += howManyLessons * (p.rate_total * (1 + p.rate_vat / 100));
                        // }
                        // amount += howManyLessons * (rateArr.instructor_rate_total * (1 + rateArr.instructor_rate_vat / 100));
                        amount += howManyLessons * rateArr
                    }
                });
                return <div>
                    <span>{amount.toFixed(2)} EUR&nbsp;</span>
                    {
                        edited !== null && edited !== undefined
                            ? <small style={{ color: "red" }}>Labots</small>
                            : ""
                    }
                </div>;
            }
        },
    ] as any;

    const rowSelection = {
        onChange: (selectedRowKeys: any, selectedRows: any) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            setSelection(() => {
                return (
                    selectedRows
                );
            })
        },
        getCheckboxProps: (record: any) => ({
            name: record.id,
        }),
    };



    useEffect(() => {
        fetchBalance(period);
    }, []);

    return (
        <Layout>
            <Row>
                <PageHeader
                    style={{ backgroundColor: "#FFFFFF", width: "100%" }}
                    title={`Instruktors: ${instructor?.first_name || ""} ${instructor?.last_name || ""}`}
                    onBack={() => history.goBack()}
                />
                <Col span={24} style={{ backgroundColor: "#FFFFFF" }}>
                    <Row justify="center" align="middle">
                        <b>Periods: </b>
                        <DatePicker.RangePicker
                            style={{ margin: 20 }}
                            defaultValue={period}
                            format="DD.MM.YYYY"
                            onChange={(e: any) => setPeriod(e)}
                        />
                        <Button
                            style={{ marginLeft: 8 }}
                            onClick={() => {
                                // if (period === null) return;
                                fetchBalance(period);
                            }}
                        >
                            Atlasīt
                        </Button>
                    </Row>
                </Col>
                <Col span={24}>
                    <Card>
                        <Row justify="end">
                            <Select
                                value={pageSize}
                                style={{ width: 150, marginBottom: 10 }}
                                onChange={(e: any) => setPageSize(e)}
                                disabled={loading}
                            >
                                <Select.Option value={10}>10 / lappuse</Select.Option>
                                <Select.Option value={20}>20 / lappuse</Select.Option>
                                <Select.Option value={50}>50 / lappuse</Select.Option>
                                <Select.Option value={100}>100 / lappuse</Select.Option>
                                <Select.Option value={"all"}>Visi ieraksti</Select.Option>
                            </Select>
                        </Row>
                        <Table
                            rowKey={record => record.slot_id}
                            rowSelection={{
                                type: 'checkbox',
                                ...rowSelection,
                            }}
                            columns={columns}
                            dataSource={instructor.student_lessons &&
                                lodash.orderBy(
                                    instructor.student_lessons,
                                    (lesson: any) => lesson.date,
                                    'desc'
                                )
                                || undefined}
                            expandable={{
                                expandedRowRender: (record: any) => {
                                    return <EditLessonPrices updateBalance={() => fetchBalance(period)} lesson={record} />
                                }
                            }}
                            loading={loading}
                            pagination={
                                pageSize === "all"
                                    ? false
                                    : {
                                        pageSize: pageSize !== "all"
                                            ? pageSize
                                            : 100
                                    }}
                            style={{ marginBottom: 20 }}
                        />
                        {
                            pageSize !== "all"
                                ? <></>
                                : <Row justify="end">
                                    <Select
                                        value={pageSize}
                                        style={{ width: 150 }}
                                        onChange={(e: any) => setPageSize(e)}
                                        disabled={loading}
                                    >
                                        <Select.Option value={10}>10 / lappuse</Select.Option>
                                        <Select.Option value={20}>20 / lappuse</Select.Option>
                                        <Select.Option value={50}>50 / lappuse</Select.Option>
                                        <Select.Option value={100}>100 / lappuse</Select.Option>
                                        <Select.Option value={"all"}>Visi ieraksti</Select.Option>
                                    </Select>
                                </Row>
                        }
                        <PayoutForm
                            lessons={selectedLessons}
                            period={period}
                            invoiceTemplate={instructor.invoice_template}
                            returnBonus={(bonus: any) => setBonus(bonus)}
                            bonus={bonus}
                            saving={saving}
                            setSaving={setSaving}
                            selectedLessons={selectedLessons}
                            howManyLessonsToOne={howManyLessonsToOne}
                            instructor={instructor}
                        />
                    </Card>
                </Col>
            </Row>
        </Layout>
    )
})